import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';

// import { getBenefitCoinsOverview } from '../redux/actions/otherActions';
import { getBenefitCoinsOverview } from '../../redux/actions/otherActions';
import CustomDatePicker from '../../components/other/CustomDatePicker';
import CurrencyFormat from '../../components/other/CurrencyFormat';
import PatchedPagination from '../../components/other/PatchedPagination';
import { useTranslation } from 'react-i18next';
import styles from "../../css/EweGoCartProfile.module.css"
const EweGoCartProfile = () => {

    const dispatch = useDispatch();
    const tableRef = createRef();
    const getBenefitOverview = useSelector((state) => state.getBenefitOverview.items)
    const [totalPoints, setTotalPoints] = useState(0);
    const { t } = useTranslation('coins');

    useEffect(() => {
        dispatch(getBenefitCoinsOverview())
    }, [])

    useEffect(() => {
        // console.log(getBenefitOverview, 'getBenefitOverview')
        var total = 0;
        getBenefitOverview.map((item) => {
            total += item.anBenefitPointSum
        })
        setTotalPoints(total);
    }, [getBenefitOverview])

    return (
        <div style={{ width: "100%" }} className={`${styles['ewe-go-cart-my-coins']}`}>
            <img
                className={`${styles['ewe-go-cart-img-top']}`}
                src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                alt="top-banner"
            />

            <div className="container-fluid">
                <div className={`${styles['ewe-go-cart-content-header']}`}>
                    <div className={`${styles['ewe-go-cart-content-header']}`}>
                        {t('vasiBodovi')}
                    </div>
                </div>
                <div className="coins-subtitle-holder">
                    <div className={`${styles['ewe-go-cart-total-subtitle']}`}>
                        {t('ukupnoBodova')}
                    </div>
                    <span className={`${styles['ewe-go-cart-total-subtitle']}`}>{totalPoints}</span>
                </div>
                <div className="row">

                    <div className="col-sm-12">
                        <MaterialTable

                            style={{ backgroundColor: 'transparent', color: '#000', border: 'none' }}
                            components={{
                                Pagination: PatchedPagination,
                                Container: props => <Paper {...props} elevation={0} className={`${styles['ewe-cart-go-style']}`} />
                            }}
                            data={getBenefitOverview}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: '',
                                    searchTooltip: '',
                                },
                                pagination: {
                                    labelRowsSelect: '',
                                    firstAriaLabel: t('prvaStrana'),
                                    firstTooltip: t('prvaStrana'),
                                    previousAriaLabel: t('prethodnaStrana'),
                                    previousTooltip: t('prethodnaStrana'),
                                    nextAriaLabel: t('sledecaStrana'),
                                    nextTooltip: t('sledecaStrana'),
                                    lastAriaLabel: t('poslednjaStrana'),
                                    lastTooltip: t('poslednjaStrana'),
                                },
                                header:
                                {
                                    actions: 'Actions'

                                },
                                body: {
                                    emptyDataSourceMessage: (
                                        <div className="loyalty-msg-holder">
                                            <div className={`alert alert-primary  ${styles['ewe-cart-go-style1']}`} role="alert">
                                                {t('ostvariteBodove')}
                                            </div>
                                        </div>
                                    ),
                                },
                            }}
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: <>{t('nazivProizvoda')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "acProductName",
                                },
                                {
                                    title: <>{t('faktura')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "acKey",
                                    type: "string",
                                },
                                {
                                    title: <>{t('datum')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "adDate",
                                    type: 'date',
                                    dateSetting: { locale: "sr-RS" },
                                    filterComponent: (props) => <CustomDatePicker {...props} />,
                                },
                                {
                                    title: <>{t('iznos')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "anPrice",
                                    render: item => {
                                        return <CurrencyFormat num={item.anPrice} />;
                                    }
                                },
                                {
                                    title: <>{t('benefitPoeni')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "anBenefitPoint",
                                },
                                {
                                    title: <>{t('ukupnoBenefitPoena')} <i className="fas fa-sort mx-1"></i></>,
                                    field: "anBenefitPointSum",
                                },
                            ]}
                            options={{
                                filtering: true,
                                search: false,
                                draggable: false,
                                thirdSortClick: false,
                                color: 'white',
                                pageSize: 15,
                                pageSizeOptions: [15, 20, 50],
                                filterCellStyle: {
                                    color: "#fff"
                                },
                                cellStyle: {
                                    padding: "8px"
                                },
                                headerStyle: {
                                    whiteSpace: "nowrap",
                                    padding: "8px",
                                    // backgroundColor: 'black',
                                    // color:'white',
                                },

                            }}
                            icons={{
                                Filter: () => <i className="fas fa-search"></i>,
                            }}
                            title=""
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-12'>
                        <div className={`${styles['ewe-cart-go-total']}`}>
                            {t('sakupiliSte')} <span>{totalPoints}</span> {t('bodova')}
                        </div>
                    </div>
                </div>
            </div>
            <img
                className={`${styles['ewe-go-cart-img-bottom']}`}
                src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                alt="bottom-banner"
            />
        </div>
    )
}

export default EweGoCartProfile