import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import CompareProductsRow from "../components/compare/CompareProductsRow";
import CompareProductsBlock from "../components/compare/CompareProductsBlock";
import CompareProductsItem from "../components/compare/CompareProductsItem";
import CompareItemTopInfo from "../components/compare/CompareItemTopInfo";
import { useTranslation } from 'react-i18next';

import { removeSpecialChar } from '../helperFunctions'

import "../css/Product.css";

const makeFilters = (com) => {
    let arrSpec = [];
    let spec = [];
    for (let i = 0; i < com.length; i++) {
        for (let y = 0; y < com[i].spec.list.length; y++) {
            arrSpec.push(com[i].spec.list[y]);
        }
    }
    let arra = arrSpec.sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
    let filtersArr = [];
    let obj = {};
    arra.forEach((item, index) => {
        if (!obj[item.acGroupName]) {
            obj[item.acGroupName] = {};
            obj[item.acGroupName]["anPriority"] = item.anPriority;
            obj[item.acGroupName]["acGroupName"] = item.acGroupName;
            obj[item.acGroupName]["anGroupId"] = item.anGroupId;
            obj[item.acGroupName]["filters"] = [];
            item.filterSet.forEach((item2) => {
                obj[item.acGroupName]["filters"].push(item2);
            });
        } else {
            item.filterSet.forEach((item2) => {
                obj[item.acGroupName]["filters"].push(item2);
            });
            obj[item.acGroupName]["filters"].sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
        }

        //filtrira da se ne duplira podgrupa
        obj[item.acGroupName]["filters"] = obj[item.acGroupName]["filters"].filter((v, i, self) => self.findIndex((itm) => itm.acFilterSet === v.acFilterSet) === i);
    });
    for (const property in obj) {
        filtersArr.push(obj[property]);
    }
    return filtersArr;
};

const ComparePage = () => {

    const params = useParams();
    const compareItems = useSelector((state) => state.compareItems.compareItems);
    const [filters, setFilters] = useState([]);
    const { t } = useTranslation(['comparePage', 'routes']);

    useEffect(() => {
        setFilters(makeFilters(compareItems));
    }, []);

    useEffect(() => {
        setFilters(makeFilters(compareItems));
    }, [compareItems]);

    return (
        <>
            {compareItems.length === 0 ? (
                <div className="container-content container-catalog-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12" style={{ marginTop: "20px" }}>
                                <div className="alert alert-primary" role="alert">
                                    <i className="fas fa-info"></i>&nbsp; {t('nemaProizvoda')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container-content container-catalog-content compare-page">
                    <div className="container-fluid">
                        <div className="row">
                            <Link to={`/${t('routes:pdfUporediProizvode')}/${removeSpecialChar(params.name)}`} target="_blank">
                                <button className="compare-page-icon btn btn-outline-danger btn-sm ">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.55 53.84">
                                        <g data-name="Layer 2">
                                            <g data-name="Layer 1">
                                                <path d="M19.32 13.92A8.06 8.06 0 0117 16.21l2 2.93a11.76 11.76 0 003.26-3.26zM4.87 21.14c3.5 0 6.25 0 8.45-.23A11.9 11.9 0 0019 19.14l-2-2.93a8.54 8.54 0 01-4 1.2c-2 .2-4.52.2-8.09.2zM20.72 1.76c0 3.57 0 6.12-.2 8.09a8.62 8.62 0 01-1.2 4.07l2.93 2A12 12 0 0024 10.21c.23-2.2.22-5 .22-8.45zM4.87 17.61H1.76v3.53h3.11zm15.92-2.71l-1.47-1z"
                                                    className="cls-1"></path>
                                                <path d="M1.64 15.11A11.27 11.27 0 00.74 17L4 18.23a8.09 8.09 0 01.63-1.33zm1.88 9.83c0-3.89 0-5.39.52-6.71L.74 17C0 19.06 0 21.32 0 24.94zM7.33 9.53c-2.81 2.28-4.58 3.69-5.69 5.58l3 1.79c.72-1.22 1.87-2.19 4.88-4.64zm12.59-5.32A8 8 0 0121 3.83L20 .44a12.09 12.09 0 00-1.49.56zM26.09 0A22.45 22.45 0 0020 .44l1 3.39a20 20 0 015.1-.31zM15.77 7.2c2.27-1.85 3.15-2.56 4.15-3L18.51 1a23 23 0 00-5 3.48zm24.84-2.09A8.45 8.45 0 0142.45 7l2.85-2.13a12 12 0 00-2.62-2.61zm6.94 14.77a89.39 89.39 0 00-.25-9 12 12 0 00-2-6L42.45 7a8.56 8.56 0 011.35 4.3c.23 2.1.23 4.81.23 8.63zM27.68 3.52c3.81 0 6.53 0 8.63.23a8.55 8.55 0 014.3 1.36l2.07-2.85a11.94 11.94 0 00-6-2C34.35 0 31.41 0 27.68 0zM42.45 46.9a8.91 8.91 0 01-1.84 1.84l2.07 2.85A12.06 12.06 0 0045.3 49zm-14.77 6.94a89.59 89.59 0 009-.25 11.93 11.93 0 006-2l-2.07-2.85a8.56 8.56 0 01-4.3 1.35c-2.1.23-4.82.23-8.63.23zM44 34c0 3.81 0 6.53-.23 8.63a8.56 8.56 0 01-1.35 4.3L45.3 49a11.93 11.93 0 002-6 89.59 89.59 0 00.25-9zM7 48.74a8.45 8.45 0 01-1.89-1.84L2.26 49a12 12 0 002.61 2.62zM0 34c0 3.73 0 6.67.25 9a11.94 11.94 0 002 6l2.86-2.1a8.55 8.55 0 01-1.36-4.3c-.22-2.1-.23-4.82-.23-8.63zm19.88 16.32c-3.82 0-6.53 0-8.63-.23A8.56 8.56 0 017 48.74l-2.13 2.85a12 12 0 006 2 89.39 89.39 0 009 .25zM3.52 34v-9H0v9zm24.16 16.32h-7.8v3.52h7.8zM44 19.88V34h3.52V19.88zM26.09 3.52h1.59V0h-1.59zM9.55 12.26l6.22-5.06-2.23-2.74-6.21 5.07zm-7.16 5.35L.74 17zM20.51 2.14L20 .44zm-1.29.46L18.51 1zm-4.57 3.23l-1.11-1.37zm29.22.08L42.45 7zm-2.23-2.23l-1 1.43zm0 46.48l1 1.43zm2.23-2.22l1.43 1zm-40.19 0L2.26 49zm2.23 2.22l1-1.42z"
                                                    className="cls-1"></path>
                                                <path d="M17.77 31.15c0 1.78-1.09 3.73-4.49 3.73h-3v3.59a.78.78 0 01-.78.76.75.75 0 01-.75-.76V28.19a.76.76 0 01.75-.77h3.83c3.35 0 4.44 1.95 4.44 3.73zm-1.53 0c0-1.1-.6-2.29-3.11-2.29h-2.9v4.57h2.9c2.51 0 3.11-1.19 3.11-2.28zM29.72 33.28a5.69 5.69 0 01-5.93 5.86h-3.38a.74.74 0 01-.75-.75v-10.2a.76.76 0 01.75-.77h3.38a5.68 5.68 0 015.93 5.86zm-1.51 0a4.27 4.27 0 00-4.61-4.43h-2.42v8.87h2.42a4.28 4.28 0 004.61-4.44zM33.5 28.85v3.71h5.18a.72.72 0 01.72.72.71.71 0 01-.72.71H33.5v4.48a.77.77 0 01-.77.76.75.75 0 01-.75-.76V28.19a.76.76 0 01.75-.77h6.54a.72.72 0 01.72.72.71.71 0 01-.72.71z"
                                                    className="cls-2"></path>
                                            </g>
                                        </g>
                                    </svg> {t('pdf')}
                                </button>
                            </Link>
                        </div>
                        <div className="row">
                            <div className="compare-page-table-content">
                                <table className="table compare-page-table">
                                    <thead className="compare-product-head">
                                        <tr>
                                            <td className="compare-product-th"></td>
                                            {compareItems.map((item, key) => {
                                                // console.log('Key:', key); // Logovanje ključa
                                                return <CompareItemTopInfo item={item} key={key} keyProp={key} />;
                                            })}

                                        </tr>
                                    </thead>
                                    {filters.map((item, index) => {
                                        if (item.filters.length > 0) {
                                            return (
                                                <CompareProductsBlock name={item.acGroupName} colspan={compareItems.length}>
                                                    {item.filters.map((item2, index2) => {
                                                        return (
                                                            <CompareProductsRow name={item2.acFilterSet}>
                                                                {compareItems.map(function (specs, index3) {
                                                                    let set = specs.spec.list.find((sp) => sp.anGroupId === item.anGroupId);
                                                                    set = set ? set : null;
                                                                    return <CompareProductsItem specs={set} filterId={item2.anFilterId} />;
                                                                })}
                                                            </CompareProductsRow>
                                                        );
                                                    })}
                                                </CompareProductsBlock>
                                            );
                                        }
                                    })}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ComparePage;