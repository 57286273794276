import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { axiosinstance } from '../../configAxios';
import { useLocation } from "react-router-dom";
// import { fetchPosts } from '../../redux/actions/productActions';
import { getFilteredProducts, getActionProductsList } from '../../redux/actions/productActions';
import { changeProductSort } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
};

const CatalogSortItem = () => {

  const [sortList, setSortList] = useState([]);
  const { categoryId, catagoryType, id } = useParams();
  const dispatch = useDispatch();
  const url = useLocation();
  let query = useQuery();
  const activeProductSort = useSelector((state) => state.activeProductSort.item);

  const functionListSort = () => {
    const attributes = {};
    const configRes = axiosinstance(attributes, '8224', 'getSort');
    axios(configRes)
      .then(function (response) {
        setSortList(response.data.response.sortList);
      })
      .catch(function (error) {

      });
  };

  useEffect(() => {
    functionListSort();
  }, [])

  const changeCatalogProducts = (val) => {
    // dispatch(fetchPosts(categoryId, catagoryType, 0, val));
    dispatch(changeProductSort(val));
    if (url.pathname.includes("specijalna-akcija")) {
      dispatch(getActionProductsList(id));
    } else {
      // console.log('query', query.toString());
      dispatch(getFilteredProducts(categoryId, catagoryType, query.toString()));
    }
  };

  return (
    <>
      <div className="catalog-sort-box-input">
        <select className="catalog-sort-select-input form-control form-control-sm" onChange={(e) => changeCatalogProducts(e.target.value)} >
          {sortList.map((item, key) => (
            <option
              key={key}
              value={item._id}
              defaultValue={item._id}
              selected={item._id == activeProductSort && 'selected'}
            >
              {item.acName}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CatalogSortItem;