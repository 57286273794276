import React, { useState, useCallback, useMemo, useEffect } from 'react';
// import Select from 'react-select';
import DataListInput from "react-datalist-input";
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { axiosinstance } from '../../configAxios';

import MessageInfoBox from '../other/MessageInfoBox';
import CurrencyFormat from '../other/CurrencyFormat';

import { getDeliveryCity, getDeliveryTime, submitFinishCartOrderFunction } from '../../redux/actions/cartActions';
import { getBankAccount } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const CartInfoDelivery = ({ cartActiveDetail, cartDeliveryCity, activeCurrency, setIsVisibleInfoBoxDanger, cartDeliveryType }) => {
    const dispatch = useDispatch();
    var belgradeCode = 11273;
    const location = useLocation();
    const history = useHistory();
    const userInfo = useSelector((state) => state.userInfo);
    const deliveryCityList = useSelector((state) => state.getDeliveryCity.items);
    const deliveryTimeList = useSelector((state) => state.getDeliveryTime.items);
    const [isDisabled, setIsDisabled] = useState(false);
    const [value, setValue] = useState(null);
    const [hiddenForm, setHiddenForm] = useState(0);
    const [dispInfo, setDispInfo] = useState(1);
    const [successBlock, setSuccessBlock] = useState(0);
    const iconPath = process.env.PUBLIC_URL + '/media/';

    const [acNote, setAcNote] = useState('');
    const [anRansomAmount, setAnRansomAmount] = useState('');
    const [acCompanyAcount, setAcCompanyAcount] = useState('');

    const [acCompanyReceipt, setAcCompanyReceipt] = useState('');
    const [acCompany, setAcCompany] = useState('');

    const [acPhone1, setAcPhone1] = useState(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acContactPhone : "");
    const [acPhoneDispatcher, setAcPhoneDispatcher] = useState(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acContactPhone : "");
    const [validInput, setValidInput] = useState({
        acPhoneDispatcher: 0,
        value: 0,
        acCompanyAcount: 0,
    });
    const [acPhone2, setAcPhone2] = useState('');
    const [acFirm, setAcFirm] = useState(userInfo.userInfo.company.acCompany);
    const [acFirmDispatcher, setAcFirmDispatcher] = useState(userInfo.userInfo.company.acCompany);
    const [acContactPerson, setAcContactPerson] = useState(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acContactPerson : "");
    const [acAddress, setAcAddress] = useState(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acDeliveryAddress : "");
    const [acAddressDispatcher, setAcAddressDispatcher] = useState(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acDeliveryAddress : "");
    const [acDelivery, setAcDelivery] = useState('I');
    const [anPostKey, setAnPostKey] = useState(userInfo.userInfo.company.acPostCode != "" ? userInfo.userInfo.company.acPostCode : "");
    const [acCity, setAcCity] = useState(userInfo.userInfo.company.acPostCode != "" && userInfo.userInfo.company.acCity != "" ? userInfo.userInfo.company.acPostCode + " " + userInfo.userInfo.company.acCity : "");
    const [bankOptions, setBankOptions] = useState({ value: "" })
    const [acCityPost, setAcCityPost] = useState(`${userInfo.userInfo.company.acPostCode} ${userInfo.userInfo.company.acCity}`);
    const [city, setCity] = useState({ label: `${userInfo.userInfo.company.acPostCode} ${userInfo.userInfo.company.acCity}`, id: userInfo.userInfo.company.acPostCode, val: userInfo.userInfo.company.acPostCode != "" ? userInfo.userInfo.company.acPostCode : "" });
    const getBankAccountItems = useSelector((state) => state.getBankAccountItems.items);

    let bankAccountItems = []
    getBankAccountItems.filter(item => {
        bankAccountItems.push({ _id: item._id, acName: item.acBankAccount })
    })

    const [show, setShow] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeCart, setPromoCodeCart] = useState({});
    const [isChecked, setIsChecked] = useState(true);
    const [isAdvancePayment, setIsAdvancePayment] = useState(0);
    const [isRansomDelivery, setIsRansomeDelivery] = useState(0);
    const [isFastDelivery, setIsFastDelivery] = useState(0);
    const [validAddress, setValidAddress] = useState(0);
    const [validAddress1, setValidAddress1] = useState(0);
    const [validAcCompany, setValidAcCompany] = useState(0);
    const [validAnRansomAmount, setValidAnRansomAmount] = useState(0);
    const [validAcCompanyAcount, setValidAcCompanyAcount] = useState(0);
    const [validAcCompanyAcountNew, setValidAcCompanyAcountNew] = useState(0);
    const [validCompany, setValidCompany] = useState(0);

    const [imgName1, setImgName1] = useState("");
    const [isSetViaInput1, setIsSetViaInput1] = useState(false);
    const { t } = useTranslation('cart');
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);

    const roundedValueWithPdv = Math.round(getActiveCartFunction.anValueWithPdv);
    const summaryRounded = parseFloat((roundedValueWithPdv - getActiveCartFunction.anValueWithPdv).toFixed(2));



    // console.log(summaryRounded, "summaryRounded")
    const items = useMemo(
        () =>
            userInfo.userInfo.company.department[0].deliveryAddress.map((item) => ({
                label: item.acDeliveryAddress,
                key: item.acDeliveryAddress,
            })),
        [userInfo.userInfo.company.department[0].deliveryAddress]
    );

    const itemsCities = useMemo(
        () =>
            deliveryCityList.map((item) => ({
                val: item._id,
                id: item.acPostCode,
                label: item.acPostCode + ' ' + item.acCity,
            })),
        [deliveryCityList]
    );

    var tura = '';
    const itemsTime = useMemo(
        () =>
            deliveryTimeList && deliveryTimeList.map((item) => ({
                id: item.adDateTime,
                label: item.adDateTime.split('T')[0] + ' ' + (item.acPartOfDat == 'F' ? t('prvaTura') : t('drugaTura'))
            })),
        [deliveryTimeList]
    );
    const submitCartFinishFunction = (isFast) => {

        var isPopupDiscountAvaible = cartActiveDetail.isPopupDiscountAvaible;

        var isPhone = 0;
        if (acPhoneDispatcher != "") {
            isPhone = 1;
        } else {
            isPhone = 2;
        }

        setIsFastDelivery(isFast);

        if (value != null) {
            setValidInput({
                ...validInput, value: 1, acPhoneDispatcher: isPhone
            })
        } else {
            setValidInput({
                ...validInput, value: 2, acPhoneDispatcher: isPhone
            })
        }

        if (acAddressDispatcher != "") {
            setValidAddress(2);
        } else {
            setValidAddress(1);
        }
        if (acAddress != "") {
            setValidAddress1(2);
        } else {
            setValidAddress1(1);
        }
        if (acFirmDispatcher != "") {
            setValidAcCompany(2);
        } else {
            setValidAcCompany(1);
        }

        if (isRansomDelivery == 1) {
            if (anRansomAmount != "") {
                setValidAnRansomAmount(2);
            } else {
                setValidAnRansomAmount(1);
            }
            if (acCompanyAcount != "") {
                if (bankOptions.value == 'novi racun') {
                    setValidAcCompanyAcountNew(2);
                } else {
                    setValidAcCompanyAcount(2);
                }
            } else {
                if (bankOptions.value == 'novi racun') {
                    setValidAcCompanyAcountNew(1);
                    setValidAcCompanyAcount(2);
                } else {
                    setValidAcCompanyAcount(1);
                }
            }
        } else {
            setValidAnRansomAmount(2);
            setValidAcCompanyAcount(2);
            setValidAcCompanyAcountNew(2);
        }

        if (city.label == '') {
            setCity({ label: '', id: '', val: 0, isError: 1 });
        }

        if (isPopupDiscountAvaible == 3) {
            if (acDelivery == 'I') {
                if (value != null && anPostKey != '' && acFirmDispatcher != '' && acAddressDispatcher != '' && acPhoneDispatcher != '') {
                    setShow(true);
                    setPromoCode('');
                    setPromoCodeCart({});

                }
            } else {
                setShow(true);
                setPromoCode('');
                setPromoCodeCart({});

            }
        } else {
            finishCartItemSubmit(isFast);
        }

    }

    const submitCartFinishFromModalFunction = () => {
        finishCartItemSubmit(isFastDelivery);
    }

    function finishCartItemSubmit(isFast) {

        var tmp = value !== null ? value.label.split(' ') : '';
        var attributes = {};
        attributes['adDate'] = '';
        attributes['acPartOfDate'] = '';

        if (value !== null) {
            attributes['adDate'] = tmp[0];
            attributes['acPartOfDate'] = tmp[1] == "Druga" ? 'S' : 'F';
        }

        attributes['acFirm'] = acFirm;
        attributes['acFirmDispatcher'] = acFirmDispatcher;
        attributes['acNote'] = acNote;
        attributes['acAddress'] = acAddressDispatcher; //ovaj podatak je izmenjen zato sto baza svuda prihvata podatak acAdress
        attributes['acAddressDispatcher'] = acAddress;
        attributes['acPhone'] = acPhoneDispatcher;
        attributes['acPhoneDispatcher'] = acPhone1;
        attributes['acPhone2'] = acPhone2;
        attributes['acDelivery'] = acDelivery;
        attributes['acContactPerson'] = acContactPerson;
        attributes['anPostKey'] = anPostKey;
        attributes['isPayment'] = 0;
        attributes['isForEndUser'] = 0;
        attributes['acDeliveryType'] = '';
        attributes['acCartKey'] = cartActiveDetail._id;
        attributes['acPromoCode'] = promoCode;
        attributes['isAdvancePayment'] = isAdvancePayment;
        attributes['isFastDelivery'] = isFast;
        attributes['acCompany'] = acFirmDispatcher;//dodato
        attributes['isRansomDelivery'] = isRansomDelivery;
        attributes['anRansomAmount'] = anRansomAmount;
        attributes['acCompanyReceipt'] = acCompanyReceipt;
        attributes['acCompanyAcount'] = acCompanyAcount;
        attributes['acCityPost'] = acCityPost;


        // Pozovi funkciju za završetak narudžbine
        if (hiddenForm == 0 && dispInfo == 1) {
            if (value == null || anPostKey == '' || acFirmDispatcher == '' || acAddressDispatcher == '' || acPhoneDispatcher == '') {
                toast(<MessageInfoBox message="errorFinishCartInputsRequired" type="0" />, {
                    className: 'red-background',
                });
            } else {
                // console.log(attributes, 'Uspešno završena porudžbina');
                dispatch(submitFinishCartOrderFunction(attributes, cartActiveDetail, acCity, activeCurrency, history, summaryRounded, roundedValueWithPdv));
            }

        } else if (dispInfo == 0) {
            attributes['acContactPerson'] = acFirmDispatcher;
            const uploadPath = "endUser";
            const formData = new FormData();
            formData.append('document', imgName1);
            if (value == null || anPostKey == '' || acFirmDispatcher == '' || acAddressDispatcher == '' || acPhoneDispatcher == '' || (isRansomDelivery == 1 && (anRansomAmount == '' || acCompanyAcount == ''))) {
                toast(<MessageInfoBox message="errorFinishCartInputsRequired" type="0" />, {
                    className: 'red-background',
                });
            } else {
                if (imgName1 != '') {
                    try {
                        axios.post('https://newb2b.ewe.rs:5000/image-upload/' + uploadPath + '', formData)
                            .then(res => {
                                if (res.data.isOk == 1) {
                                    attributes['acFilePath'] = `/media/${uploadPath}/${res.data.fileName}`
                                    // console.log(attributes, 'Uspešno završena porudžbina');
                                    dispatch(submitFinishCartOrderFunction(attributes, cartActiveDetail, acCity, activeCurrency, history, summaryRounded, roundedValueWithPdv));
                                } else {
                                    toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                                        className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                                    });
                                    setShow(false)
                                }
                            })
                    } catch (error) {
                        console.log(error);
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    }
                } else {
                    attributes['acFilePath'] = '';
                    // console.log(attributes, 'Uspešno završena porudžbina');
                    dispatch(submitFinishCartOrderFunction(attributes, cartActiveDetail, acCity, activeCurrency, history, summaryRounded, roundedValueWithPdv));
                }
            }
        } else {
            // console.log(attributes, 'Uspešno završena porudžbina');
            dispatch(submitFinishCartOrderFunction(attributes, cartActiveDetail, acCity, activeCurrency, history, summaryRounded, roundedValueWithPdv));
        }
    }


    const onSelect = useCallback((selectedItem) => {
        setAcAddressDispatcher(selectedItem.label);

        userInfo.userInfo.company.department[0].deliveryAddress.map((item, key) => {
            if (item.acDeliveryAddress == selectedItem.label) {
                setAcContactPerson(item.acContactPerson);
                setAcPhone1(item.acContactPhone);
                setAcPhoneDispatcher(item.acContactPhone);
            }
        });

    }, []);
    const onInput = useCallback((selectedItem) => {
        setAcAddressDispatcher(selectedItem);
    }, []);

    const cityChangeFunction = (selectedValue) => {
        if (selectedValue) {
            var postKey = selectedValue

            setAnPostKey(selectedValue.val);
            setAcCity(selectedValue.label)
            dispatch(getDeliveryTime(selectedValue.id));
            setIsDisabled(false);

            if (postKey[1] < belgradeCode) {
                cartDeliveryCity(1);
            } else {
                cartDeliveryCity(0);
            }

        } else {
            setAnPostKey('');
            setIsDisabled(true);
        }
        setValue(null);
    }

    const isNumber = (n) => { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    useEffect(() => {
        dispatch(getDeliveryCity());
        dispatch(getDeliveryTime(anPostKey));
        dispatch(getBankAccount());
        if (!isNumber(anPostKey)) {
            var tmp = anPostKey.split('-');
            if (tmp[1] < belgradeCode) {
                cartDeliveryCity(1);
            } else {
                cartDeliveryCity(0);
            }
        }
        if (deliveryCityList) {
            deliveryCityList.forEach((item) => {
                if (item.acPostCode == userInfo.userInfo.company.acPostCode) {
                    setAnPostKey(item._id)
                }
            })
        }
    }, [])

    useEffect(() => {
        deliveryCityList.forEach((item) => {
            if (item.acPostCode == userInfo.userInfo.company.acPostCode) {
                setAnPostKey(item._id)
            }
        })
    }, [deliveryCityList])

    const setAcDeliveryFunction = (vall) => {
        // console.log(vall, "valll");
        setAcDelivery(vall);
        // setValue(null);
        // setIsDisabled(true);
        if (vall == 'Z' || vall == 'L') {
            setHiddenForm(1);
            setDispInfo(1);
            setIsVisibleInfoBoxDanger(false);
            setAnPostKey(0);
            setAcAddressDispatcher(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acDeliveryAddress : "");
            setAcFirmDispatcher(userInfo.userInfo.company.acCompany);
        } else if (vall == 'I') {
            setHiddenForm(0);
            setDispInfo(1);
            setIsVisibleInfoBoxDanger(false);
            setAcAddressDispatcher(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acDeliveryAddress : "");
            setAcFirmDispatcher(userInfo.userInfo.company.acCompany);
            setAnPostKey(userInfo.userInfo.company.anPostCodeKey != "" ? userInfo.userInfo.company.anPostCodeKey : 0);
            setCity({ label: `${userInfo.userInfo.company.acPostCode} ${userInfo.userInfo.company.acCity}`, id: userInfo.userInfo.company.acPostCode, val: userInfo.userInfo.company.acPostCode });
            setAcCity(userInfo.userInfo.company.acPostCode != "" && userInfo.userInfo.company.acCity != "" ? userInfo.userInfo.company.acPostCode + " " + userInfo.userInfo.company.acCity : "");
            dispatch(getDeliveryTime(userInfo.userInfo.company.acPostCode));
            setValue(null);
            setIsDisabled(false);
        }
        else if (vall == 'K') {
            setIsVisibleInfoBoxDanger(true);
            setHiddenForm(0);
            setDispInfo(0);
            setAcAddressDispatcher('')
            setAcFirmDispatcher('');
            setAnPostKey(0);
            setAcCity('');
            setCity({ label: '', id: '', val: 0, isError: 0 });
            setValue(null);
            setIsDisabled(true);
        } else {
            setHiddenForm(0);
            setDispInfo(1);
            setIsVisibleInfoBoxDanger(false);
            setAcAddressDispatcher(userInfo.userInfo.company.department[0].deliveryAddress.length > 0 ? userInfo.userInfo.company.department[0].deliveryAddress[0].acDeliveryAddress : "");
            setAcFirmDispatcher(userInfo.userInfo.company.acCompany);
        }
    }

    const handleClose = () => {
        setShow(false);
    };

    const checkPromoCodeCart = () => {

        var promoCodeValue = promoCode;

        const attributes = {};
        attributes['anCompanyKey'] = userInfo.userInfo.company.anCompanyKey;
        attributes['acPromoCode'] = promoCodeValue;
        attributes['anCurrency'] = activeCurrency._id;
        attributes['anUserKey'] = userInfo.userInfo.company.department[0].user[0].anUserKey;

        const configRes = axiosinstance(attributes, '8224', 'postCheckPromoCode');

        axios(configRes)
            .then(function (response) {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });

                if (response.data.response.isOk == 1) {
                    setPromoCodeCart(response.data.response.cartList[0]);
                }

            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
    const onChangeFunction = (e) => {
        setAcPhoneDispatcher(e.target.value)
    };
    const onChangeCheckbox = (e) => {
        setIsChecked(!isChecked);
        if (isChecked) {
            setIsAdvancePayment(1);
        } else {
            setIsAdvancePayment(0);
        }

    };
    const onChangeRansome = (e) => {
        setIsChecked(!isChecked);
        if (isChecked) {
            setIsRansomeDelivery(1);
        } else {
            setIsRansomeDelivery(0);
        }

    };

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const [errors, setErrors] = useState({
        acDocument1: 1,
        acCompanyAcount: 1,
    })

    const handleFileInput = (e) => {
        e.preventDefault();

        if (e.target.name == "acDocument1") {
            setImgName1(e.target.files[0])
            setValidInput({
                ...validInput,
                acDocument1: 2,
            });
            setErrors({
                ...errors,
                acDocument1: 0
            })
            setIsSetViaInput1(true);
        }
    }
    const onChangeBankAccFunction = (e) => {
        if (e.target.value == "novi racun") {
            setAcCompanyAcount('');
        } else {
            setAcCompanyAcount(e.target.value);
        }
    };

    let contentToShow = null;
    if (dispInfo === 0) {
        contentToShow = (
            <>
                <div className='cart-dispInfo-holder'>
                    <div className="info-payment-address">
                        <div className="info-payment-address-content">
                            <div className="delivery-form-name-payment">
                                {t('naplataNaAdresi')}
                            </div>
                            <div className="delivery-form-buttons-payment">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="isRansomDelivery"
                                        id="inlineRadio1"
                                        value={1}
                                        checked={isRansomDelivery === 1}
                                        onChange={(e) => onChangeRansome(e)}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">{t('da')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="isRansomDelivery"
                                        id="inlineRadio2"
                                        value={0}
                                        checked={isRansomDelivery === 0}
                                        onChange={(e) => onChangeRansome(e)}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">{t('ne')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="options-payment" style={{ marginTop: '10px' }}>
                            <div className='row'>
                                <div className="form-group col-sm-6 ">
                                    <label>{t('iznosOtkupa')}</label>
                                    <input
                                        type="text"
                                        className={`form-control custom-input-form-cart-item ${validAnRansomAmount == 1 && 'is-invalid'}`}
                                        value={anRansomAmount}
                                        onChange={(e) => setAnRansomAmount(e.target.value)}
                                        disabled={isRansomDelivery === 0}
                                    />
                                    {validAnRansomAmount == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>{t('brRacunaZaUplatu')}</label>
                                    <select
                                        className={`form-control ${validAcCompanyAcount == 1 && 'is-invalid'}`}
                                        name={"acCompanyAcount"}
                                        onChange={(e) => {
                                            if (e.target.value == "novi racun") {
                                                setBankOptions({ value: "novi racun" })
                                                onChangeBankAccFunction(e)
                                            } else {
                                                setBankOptions({ value: "" })
                                                onChangeBankAccFunction(e)
                                            }
                                        }}
                                        value={bankOptions.value == "novi racun" ? "novi racun" : acCompanyAcount}
                                        disabled={isRansomDelivery == 0 ? true : false}
                                    >
                                        <option value="">{t('izaberite')}</option>
                                        {bankAccountItems.map((item) => (
                                            <option key={item._id} value={item.acName}>{item.acName}</option>
                                        ))}
                                        <option value="novi racun">{t('noviRacun')}</option>
                                    </select>
                                    {validAcCompanyAcount == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                                </div>
                                {bankOptions.value == "novi racun" &&
                                    <div className="col-sm-6">
                                        <div className={`form-group delivery-form-input-margin`}>
                                            <label>{t('noviBracnaZaUplatu')}</label>
                                            <input
                                                type="number"
                                                name="acCompanyAcount"
                                                className={`form-control ${validAcCompanyAcountNew == 1 && 'is-invalid'}`}
                                                placeholder="Unesite broj računa..."
                                                value={acCompanyAcount}
                                                onChange={(e) => {
                                                    if (bankOptions.value == "novi racun") {
                                                        onChangeBankAccFunction(e)
                                                    }
                                                }}
                                                disabled={isRansomDelivery == 0 ? true : false}
                                            />
                                            {validAcCompanyAcountNew == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className='cart-dispInfo-holder'>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group col-sm-6">
                                <label>{t('brIseckaIliFakture')}</label>
                                <input
                                    type="text"
                                    className={`form-control custom-input-form-cart-item`}
                                    value={acCompanyReceipt}
                                    onChange={(e) => setAcCompanyReceipt(e.target.value)}
                                />
                            </div>
                            <div className="delivery-file-att-content">
                                <div className="delivery-file-att-item">
                                    {t('isecakIliFaktura')}
                                    <label>
                                        <span className="delivery-label">{t('okaciSliku')} <i className="fas fa-file-upload"></i></span>
                                        <input type="file" name="acDocument1" onChange={handleFileInput} onClick={resetFileValue} />
                                    </label>
                                    {imgName1 != "" && <span className="delivery-img-name">{imgName1.name}</span>}
                                    {validInput.acDocument1 == 1 &&
                                        <div className="invalid-feedback d-block">
                                            {t('prilozDokument')}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (dispInfo === 1) {
        <div></div>
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <div className="main-cart-delivery-content">
                        <div className="delivery-box-cart-main">
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCheckbox()}
                                    id="avansCheckboxId"
                                />
                                <label htmlFor="avansCheckboxId">&nbsp; {t('avansnoPlacanje')}</label>
                            </div>
                            <div className="form-group">
                                <label>{t('nacinDostave')}</label>
                                {/* <select name="" className="form-control" onChange={(e) => setAcDeliveryFunction(e.target.value)}>
                                   
                                    {cartDeliveryType !== undefined && cartDeliveryType.map((item, key) => (
                                        <option
                                            key={key}
                                            value={item.acType}
                                            defaultValue={item.acType}
                                        >
                                            {item.acName}
                                        </option>
                                    ))}
                                </select> */}
                                <select
                                    name=""
                                    className="form-control"
                                    onChange={(e) => setAcDeliveryFunction(e.target.value)}
                                >
                                    {cartDeliveryType !== undefined && cartDeliveryType.length > 0 ? (
                                        cartDeliveryType.map((item, key) => (
                                            <option
                                                key={key}
                                                value={item.acType}
                                                defaultValue={item.acType}
                                            >
                                                {item.acName}
                                            </option>
                                        ))
                                    ) : (
                                        <>
                                            <option value="I">{t('naMojuAdresu')}</option>
                                            <option value="K">{t('krajnjemK')}</option>
                                            <option value="Z">{t('licnoUzemunu')}</option>
                                            <option value="L">{t('licnoUlestanima')}</option>
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className={`form-group ${hiddenForm == 1 && 'hidden-form-inputs'}`}>
                                <label>{t('grad')}</label>
                                <Autocomplete
                                    id="custom-input-demo"
                                    options={itemsCities}
                                    onChange={(event, val) => {
                                        cityChangeFunction(val)
                                        setCity({ ...val, isError: 2 });
                                    }}
                                    className={`${city.isError == 1 && 'is-invalid-autocomplete-input'}`}
                                    value={city.label}
                                    renderInput={(params) => {
                                        return (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text" {...params.inputProps} />
                                            </div>
                                        )
                                    }}
                                />
                                {city.isError == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                            </div>
                            <div className={`form-group ${hiddenForm == 1 && 'hidden-form-inputs'}`}>
                                <label>{t('vremeIsporuke')}</label>
                                <Autocomplete
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValidInput({
                                            ...validInput, value: 1
                                        })
                                        if (typeof newValue === 'string') {
                                            setValue({
                                                title: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setValue({
                                                title: newValue.inputValue,
                                            });
                                        } else {
                                            setValue(newValue);
                                        }
                                    }}
                                    id="custom-input-demo"
                                    disabled={isDisabled}
                                    options={itemsTime}
                                    className={`${validInput.value == 2 && 'is-invalid-autocomplete-input'}`}
                                    clearOnBlur={true}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <input placeholder={t('izaberiteVisporuke')} type="text" {...params.inputProps} />
                                        </div>
                                    )}
                                />
                                {validInput.value == 2 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                            </div>
                            <div className="form-group">
                                <label>{t('napomena')}</label>
                                <textarea className="form-control" rows="4" onChange={(e) => setAcNote(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-sm-6 ${hiddenForm == 1 && 'hidden-form-inputs'}`}>
                    <div className="main-cart-address-info-content">
                        <div className="address-box-cart-main">
                            <div className="form-group">
                                <label>{dispInfo === 0 ? t('imeIprezime') : t('naziv')}:</label>
                                {dispInfo === 0 ? (
                                    <>
                                        <input
                                            type="text"
                                            className={`form-control custom-input-form-cart-item ${validAcCompany == 1 && 'is-invalid'}`}
                                            onChange={(e) => setAcFirmDispatcher(e.target.value)}
                                            value={acFirmDispatcher}
                                        />
                                        {validAcCompany == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type="text"
                                            className={`form-control custom-input-form-cart-item ${validAcCompany == 1 && 'is-invalid'}`}
                                            onChange={(e) => setAcFirmDispatcher(e.target.value)}
                                            value={acFirmDispatcher}
                                        />
                                        {validAcCompany == 1 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                                    </>
                                )}
                            </div>
                            <div className="form-group">
                                <label>{t('adresaDostave')}</label>
                                {dispInfo === 0 ? (
                                    <>
                                        <input
                                            type="text"
                                            className={`form-control custom-input-form-cart-item ${validAddress == 1 && 'is-invalid'}`}
                                            value={acAddressDispatcher}
                                            onChange={(e) => setAcAddressDispatcher(e.target.value)}
                                        />
                                        {validAddress == 1 && <div className="text-input-required-red">{t('obaveznoPolje')}</div>}
                                    </>
                                ) : (
                                    <>
                                        {items.length > 0 ? (
                                            <DataListInput
                                                value={acAddressDispatcher}
                                                items={items}
                                                inputClassName={`form-control ${validAddress == 1 && 'is-invalid'}`}
                                                onSelect={onSelect}
                                                onInput={onInput}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={`form-control custom-input-form-cart-item ${validAddress == 1 && 'is-invalid'}`}
                                                value={'test2'}
                                                onChange={(e) => setAcAddressDispatcher(e.target.value)}
                                            />
                                        )}
                                        {validAddress == 1 && <div className="text-input-required-red">{t('obaveznoPolje')}</div>}
                                    </>
                                )}
                            </div>
                            {dispInfo !== 0 && (
                                <div className="form-group">
                                    <label>{t('kontaktOsoba')}</label>
                                    <input
                                        type="text"
                                        className="form-control custom-input-form-cart-item"
                                        value={acContactPerson}
                                        onChange={(e) => setAcContactPerson(e.target.value)}
                                    />
                                </div>
                            )}

                            <div className="form-group">
                                <label>{t('kontaktTelefon')}</label>
                                <input
                                    type="text"
                                    className={`form-control custom-input-form-cart-item ${validInput.acPhoneDispatcher == 2 && 'is-invalid'}`}
                                    value={acPhoneDispatcher}
                                    onChange={(e) => onChangeFunction(e)} />
                                {validInput.acPhoneDispatcher == 2 && (<div className="text-input-required-red">{t('obaveznoPolje')}</div>)}
                            </div>
                            <div className="form-group">
                                <label>{t('kontaktTelefon2')}</label>
                                <input type="text" className="form-control custom-input-form-cart-item" onChange={(e) => setAcPhone2(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {contentToShow}
            </div>
            <div className='buttons-holder'>

                <div className="row">
                    <div className='col-lg-6'>
                        <button className="btn btn-lg btn-success order-change-button" onClick={(e) => submitCartFinishFunction(0)}>
                            <div className="d-flex">
                                <div className="btn-cart-finish-icon finish-cart-icons">
                                    <img src={`${iconPath}icon-vasa-korpa.svg`} alt="vasa-korpa" />
                                </div>
                                <div className="btn-cart-finish-text">
                                    {t('potvdraPorudz')}<br /><span>{t('izmenaSaKomercijalistom')}</span>
                                </div>
                            </div>
                        </button>
                        <div className="order-button-info-text"><i className='fas fa-info info-icon'></i><div className="span-info-order-box">{t('vasaPorudzb')}</div></div>
                    </div>
                    <div className='col-lg-6'>
                        <button className="btn btn-lg btn-success order-quick-button" onClick={(e) => submitCartFinishFunction(1)}>
                            <div className="d-flex">
                                <div className="btn-cart-finish-icon finish-cart-icons">
                                    <img src={`${iconPath}icon-vasa-korpa.svg`} alt="vasa-korpa" />
                                </div>
                                <div className="btn-cart-finish-text">
                                    {t('direknaPorudzbina')}<br /><span>{t('brzaObradaPor')}</span>
                                </div>
                            </div>
                        </button>
                        <div className="order-button-info-text"><i className='fas fa-info info-icon'></i><div className="span-info-order-box">{t('fakturisanaIobradjena')}</div></div>
                    </div>
                </div>

            </div>

            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                dialogClassName='modal-l'
                aria-labelledby=""
                centered
            >
                <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                    <i className="far fa-times-circle"></i>
                </div>
                <Modal.Header>
                    <h4>{t('potvrdaKupovine')}</h4>
                </Modal.Header>
                <Modal.Body>
                    {cartActiveDetail.isPopupDiscountAvaible == 3 && (
                        <div className="promo-code-content">
                            <div className="promo-code-content-input">
                                <div className="input-group-sm mb-2">
                                    <label>{t('promoKod')}</label>
                                    <div className="small-info-promo-code">
                                        <small>{t('raspolazete')}</small>
                                    </div>
                                </div>
                                <div className="input-group input-group-sm mb-2">
                                    <input
                                        type="text"
                                        className="form-control input-sm"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key == 'Enter') {
                                                checkPromoCodeCart()
                                            }
                                        }}
                                    />
                                    <button className="btn btn-sm btn-info promo-code-button-check" onClick={(e) => checkPromoCodeCart()}>{t('primeniPkod')}</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="main-cart-total-sum-block">
                        <div className="table-cart-sum">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="main-cart-table-sum-name">{t('iznos')}</td>
                                        <td className="main-cart-table-sum-value">
                                            <CurrencyFormat
                                                num={Object.keys(promoCodeCart).length > 0 ? promoCodeCart.anValueNoPdv : cartActiveDetail.anValueNoPdv}
                                            /> {Object.keys(promoCodeCart).length > 0 ? (promoCodeCart.anValueNoPdv != 0 && activeCurrency.acShortName) : (cartActiveDetail.anValueNoPdv != 0 && activeCurrency.acShortName)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="main-cart-table-sum-name">{t('vrednostPK')}</td>
                                        <td className="main-cart-table-sum-value">
                                            - <CurrencyFormat
                                                num={Object.keys(promoCodeCart).length > 0 ? promoCodeCart.anVoucher : cartActiveDetail.anVoucher}
                                            /> {Object.keys(promoCodeCart).length > 0 ? (promoCodeCart.anValueNoPdv != 0 && activeCurrency.acShortName) : (cartActiveDetail.anValueNoPdv != 0 && activeCurrency.acShortName)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="main-cart-table-sum-name">{t('porez')}</td>
                                        <td className="main-cart-table-sum-value">
                                            <CurrencyFormat
                                                num={Object.keys(promoCodeCart).length > 0 ? promoCodeCart.anPdv : cartActiveDetail.anPdv}
                                            /> {Object.keys(promoCodeCart).length > 0 ? (promoCodeCart.anValueNoPdv != 0 && activeCurrency.acShortName) : (cartActiveDetail.anValueNoPdv != 0 && activeCurrency.acShortName)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="main-cart-table-sum-name total-sum-cart-main-td">{t('ukupnoZaUplatu')}</td>
                                        <td className="main-cart-table-sum-value total-sum-cart-main-td">
                                            <CurrencyFormat
                                                num={Object.keys(promoCodeCart).length > 0 ? promoCodeCart.anValueWithPdv : cartActiveDetail.anValueWithPdv}
                                            /> {Object.keys(promoCodeCart).length > 0 ? (promoCodeCart.anValueNoPdv != 0 && activeCurrency.acShortName) : (cartActiveDetail.anValueNoPdv != 0 && activeCurrency.acShortName)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-grey" onClick={handleClose}>{t('zelimDaNastavim')}</button>
                    <button className="btn btn-sm btn-success" onClick={(e) => submitCartFinishFromModalFunction()}>{t('potvrdaKupovine')}</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CartInfoDelivery;