import React from 'react';
import { useTranslation } from 'react-i18next';

function CurrencyFormat(num) {
  // return num == null ? 0 : num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return num == null ? 0 : num.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const CartInfoBox = ({ text, icon, type, activeCurrency, anFXRatePostponed, totalSum, isBelgrade, b2bSettings }) => {
  const { t } = useTranslation('cart');
  var textNew = '';
  var totalSumTmp = 0;
  var deliveryPrice = 250;

  if (isBelgrade == 1) {
    if (activeCurrency.acShortName !== 'EUR') {
      totalSumTmp = b2bSettings.items.anValueForFreeDelivery1 - totalSum;
      deliveryPrice = b2bSettings.items.anDeliveryPrice1;
    } else {
      totalSumTmp = b2bSettings.items.anValueForFreeDelivery1 / anFXRatePostponed - totalSum;
      deliveryPrice = b2bSettings.items.anDeliveryPrice1 / anFXRatePostponed;
    }
  } else {
    if (activeCurrency.acShortName !== 'EUR') {
      totalSumTmp = b2bSettings.items.anValueForFreeDelivery2 - totalSum;
      deliveryPrice = b2bSettings.items.anDeliveryPrice2;
    } else {
      totalSumTmp = b2bSettings.items.anValueForFreeDelivery2 / anFXRatePostponed - totalSum;
      deliveryPrice = b2bSettings.items.anDeliveryPrice2 / anFXRatePostponed;
    }
  }


  if (type == 'success') {
    textNew = t('besplatnaIsporuka');
  } else if (type == 'danger') {
    textNew =
      t('isporukaRobe') + ` <b>${CurrencyFormat(deliveryPrice)} ${activeCurrency.acShortName} + PDV</b>)<br/>`
      + t('nedostajeZaBesplatnu') + ` <b>${CurrencyFormat(totalSumTmp)} ${activeCurrency.acShortName}</b>
    `;
  } else if (type == 'info') {
    textNew = t('robaNaRezervaciji');
  }

  return (
    <>
      <div className="cart-info-box-info"><i className={`${icon} ${type}-icon`}></i> <div className="span-info-cart-box" dangerouslySetInnerHTML={{ __html: textNew }} ></div></div>
    </>
  );
};

export default CartInfoBox;