// appVersionCheck.js
import SecureLS from 'secure-ls';
var ls = new SecureLS({ encodingType: 'aes' });

const APP_VERSION = '1.0.6'; // Ovde stavite trenutnu verziju aplikacije ukoliko je potrebno da se obrise localStorage sa klijentskih masina
if (typeof (Storage) !== "undefined") {
    const storedVersion = localStorage.getItem('appVersion') ? localStorage.getItem('appVersion') : '';

    if (APP_VERSION !== storedVersion) {
        // console.log('App version has changed! APP_VERSION: ', APP_VERSION, ' storedVersion: ', storedVersion);
        localStorage.clear();
        ls.clear();
        localStorage.setItem('appVersion', APP_VERSION);
        window.location.reload();
    } else {
        // console.log('App version is the same! APP_VERSION: ', APP_VERSION, ' storedVersion: ', storedVersion);
    }
} else {
    // console.log('Sorry! No Web Storage support..');
}