import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import CurrencyFormat from "../other/CurrencyFormat";
import InputQtyPlusMinus from "../other/InputQtyPlusMinus";
import AddToCartButton from "../cart/AddToCartButton";

import CatalogProductBadge from '../catalog/CatalogProductBadge';
import CatalogProductBadgeImage from '../catalog/CatalogProductBadgeImage';
import CatalogProductBadgeAction from '../catalog/CatalogProductBadgeAction';

import globalVariable from '../../global';
import { removeSpecialChar } from '../../helperFunctions';
import { useTranslation } from 'react-i18next';


const BlockActionSliderItem = ({ isHomepage, item, isMaxWidth, type }) => {
    const [qty, setQty] = useState(0);
    const [mouseMoved, setMouseMoved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation(['block', 'routes']);

    const activeCart = useSelector((state) => state.getActiveCartFunction);
    const getUserInfo = useSelector((state) => state.userInfo);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const userInfo = useSelector((state) => state.userInfo);
    const isLoggedIn = userInfo.userInfo;

    const [qtyProduct, setQtyProduct] = useState(1);
    const [target, setTarget] = useState("");
    const [whereTo, setWhereTo] = useState(`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`);

    const iconPath = process.env.PUBLIC_URL + '/media/';
    const getB2bSettings = useSelector((state) => state.getB2bSettings);

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    };

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    const handleClick = (linkUrl, e) => {
        if (!mouseMoved) {
            if (e.button === 1) {
                setTarget("_blank")
                setWhereTo(linkUrl);
            } else {
                setWhereTo(linkUrl);
                setTarget("")
            }
        }
    };

    var imageClass = '';
    if (isHomepage == 'true') {
        imageClass = 'homepage-slider-special-action-image-new';
    } else {
        imageClass = 'slider-special-action-image-new';
    }

    var priceClassName = 'spec-action-price';
    if (getUserInfo.userInfo == null) {
        priceClassName = 'spec-action-price-offline';
    }

    return (

        <div className={`slider-item-detail-container ${imageClass}`}>
            <div className="slider-item-detail">
                {(type == "relatedProducts" || type == "viewedProducts") &&
                    <div className="prodcut-badges-holder">
                        {getUserInfo.userInfo && item.specialList != null && item.specialList.length > 0 && (
                            item.specialList.map((item2, key2) => (
                                <CatalogProductBadgeAction view="classicItem" key={key2} item={item2} />
                            ))
                        )}
                        {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                            <>
                                {item.acIconType == 0 && (
                                    <CatalogProductBadge view="classicItem" key={key} item={item} />
                                )}
                            </>
                        ))}
                    </div>
                }
                <div className="slider-item-detail-image relative-for-benefit">
                    <Link
                        onMouseMove={() => setMouseMoved(true)}
                        onMouseDown={() => setMouseMoved(false)}
                        onMouseUp={(e) => handleClick(`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`, e)}
                        title={item.acName}
                        target={target}
                        to={whereTo}
                    >
                        <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} className="img-fluid" alt={`${item.acName}`} onError={(e) => addDefaultSrc(e)} />
                    </Link>
                    <div className="product-catalog-bedge-go-cart-holder">
                        {item.benefitPoints && isLoggedIn && item.benefitPoints.length > 0 && item.benefitPoints.map((item, key) => (

                            <div className="product-catalog-bedge-go-cart" title={`U toku je prikpljanje poena. Ovaj artikal vam donosi ${item.anPoints} poena`}>
                                <span className='product-catalog-badge-coin-value-2'>{item.anPoints}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="slider-item-detail-title">
                    <Link
                        onMouseMove={() => setMouseMoved(true)}
                        onMouseDown={() => setMouseMoved(false)}
                        onMouseUp={(e) => handleClick(`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`, e)}
                        title={item.acSeoHeader.replace(" 1****", "")}
                        target={target}
                        to={whereTo}
                    >
                        {item.acSeoHeader ? item.acSeoHeader.replace(" 1****", "") : (item.acDept.replace(" 1****", "") + ' ' + item.acName)}
                    </Link>
                </div>
                <div className="slider-item-detail-content">
                    <div className="slider-item-detail-content-row">
                        <div className="slider-item-detail-content-row-item-left">{item.acProduct}</div>
                        {getUserInfo.userInfo != null && (
                            <div className="slider-item-detail-content-row-item-right">{t('block:rezervisano')}: {item.anReserved}</div>
                        )}
                    </div>
                    <div className="slider-item-detail-content-row">
                        {getUserInfo.userInfo != null && (
                            <div className="slider-item-detail-content-row-item-left">{t('block:lager')}: {item.anStock}</div>
                        )}
                        {item.anOldPrice > 0 && (
                            <div className="slider-item-detail-content-row-item-right slider-item-detail-content-old-price">
                                <CurrencyFormat num={item.anOldPrice} /> {item.anOldPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || '')}
                            </div>
                        )}
                    </div>
                    <div className="slider-item-detail-content-row">
                        <div className="slider-item-detail-content-row-item-full-width slider-item-detail-content-price">
                            <CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo.userInfo ? item.anPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || '') : item.anRetailPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || '')}
                        </div>
                    </div>
                </div>
                <div className="slider-item-detail-button">
                    {getUserInfo.userInfo != null ? (
                        <>
                            {item.anStock === item.anReserved ? (
                                <div className="btn btn-sm btn-call-actions w-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.72 48.73">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-1" d="M20,13.65a4.07,4.07,0,0,0,.59-3.07l-3.45.72a.53.53,0,0,1-.08.41Zm12.44,35a17,17,0,0,0,8.93-1.5L39.81,44a13.46,13.46,0,0,1-7.08,1.17ZM21.77,29.11a24.48,24.48,0,0,1-4.07-5.43,10.87,10.87,0,0,1-1.55-4.31H12.62a13.89,13.89,0,0,0,2,6,28.11,28.11,0,0,0,4.68,6.25ZM29.48,34a6.34,6.34,0,0,1-3.2-1.14,26,26,0,0,1-4.51-3.72l-2.48,2.51a29.94,29.94,0,0,0,5.15,4.21,9.71,9.71,0,0,0,5,1.66ZM36.7,29.8a7.9,7.9,0,0,0-6,2.62c-.28.29-.54.58-.75.82s-.39.44-.54.58c-.34.34-.28.15,0,.15v3.52a3.53,3.53,0,0,0,2.45-1.16c.24-.24.49-.52.7-.75s.42-.48.64-.71a4.41,4.41,0,0,1,3.43-1.55ZM47.52,39a14,14,0,0,0-4.27-6.36A10.55,10.55,0,0,0,36.7,29.8v3.52a7.08,7.08,0,0,1,4.24,2A10.46,10.46,0,0,1,44.15,40Zm-6.2,8.16a16.48,16.48,0,0,0,4.59-3A5.1,5.1,0,0,0,47.52,39l-3.37,1c.09.3.07.79-.74,1.62A13.42,13.42,0,0,1,39.81,44ZM10.66,37.6C19.52,46,27,48.13,32.39,48.65l.34-3.51c-4.62-.44-11.37-2.25-19.65-10.09ZM0,11.8A30.69,30.69,0,0,0,1.39,23.21c1.42,4.23,4.16,8.85,9.18,14.31l2.6-2.39c-4.79-5.2-7.22-9.4-8.44-13A26.91,26.91,0,0,1,3.57,11.92ZM10.14,0a7.18,7.18,0,0,0-2.9.79A13.26,13.26,0,0,0,4.1,3,12.58,12.58,0,0,0,0,11.8l3.53.12A9,9,0,0,1,6.5,5.54,9.4,9.4,0,0,1,8.77,4a4,4,0,0,1,1.37-.45Zm10.4,10.58C20,8,18,5.43,16.1,3.57a20,20,0,0,0-3-2.42A9.09,9.09,0,0,0,11.71.37,4,4,0,0,0,10.14,0V3.52c-.11,0-.07,0,.18.09a6.62,6.62,0,0,1,.89.49,16.18,16.18,0,0,1,2.43,2,12,12,0,0,1,3.45,5.21Zm-4.39,8.79a2.34,2.34,0,0,1,.28-.92A11.18,11.18,0,0,1,17.38,17c.41-.55.85-1.09,1.31-1.65s.89-1.12,1.26-1.66L17,11.71c-.26.38-.61.83-1.06,1.39s-.93,1.14-1.39,1.76a13.29,13.29,0,0,0-1.29,2,5.65,5.65,0,0,0-.65,2.47Zm2.33-6.69-1.47-1ZM40.57,45.56,39.81,44Zm-8,1.34-.17,1.75Zm13.28-7.4L47.52,39Zm-34-3.18-1.3,1.2.09.08Zm2.52-16.95h0Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    &nbsp; {t('block:pozovite')}
                                </div>
                            ) :
                                (
                                    <div className="slider-special-action-button">
                                        <div className="add-btn-spec-input-qty">
                                            <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />
                                        </div>
                                        <div className="add-btn-spec-action" >
                                            <div className="btn btn-sm btn-secondary w-100 block-action-slider-cart-button">
                                                <AddToCartButton qtyValue={qtyProduct} product={item} name={t('block:dugme')} activeCart={activeCart} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    ) : (
                        <div className="slider-special-action-button">
                            <Link className="w-100" to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acSeoUrl)}`}>
                                <button className="btn btn-success w-100 btn-sm"><i className="fas fa-external-link-square-alt"></i> {t('block:detaljnije')}</button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>

        // <div className="slider-item-spec" style={isMaxWidth < 5 ? { 'maxWidth': '300px' } : {}}>
        //     <div className="item-slider-special-action">
        //         {(type == "relatedProducts" || type == "viewedProducts") &&
        //             <div className="prodcut-badges-holder">
        //                 {getUserInfo.userInfo && item.specialList != null && item.specialList.length > 0 && (
        //                     item.specialList.map((item2, key2) => (
        //                         <CatalogProductBadgeAction view="classicItem" key={key2} item={item2} />
        //                     ))
        //                 )}
        //                 {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
        //                     <>
        //                         {item.acIconType == 0 && (
        //                             <CatalogProductBadge view="classicItem" key={key} item={item} />
        //                         )}
        //                     </>
        //                 ))}
        //             </div>
        //         }
        //         <div className={imageClass}>
        //             <Link
        //                 onMouseMove={() => setMouseMoved(true)}
        //                 onMouseDown={() => setMouseMoved(false)}
        //                 onMouseUp={(e) => handleClick(`/${t('routes:proizvod')}/${item.anProductKey}`, e)}
        //                 target={target}
        //                 to={whereTo}
        //             >
        //                 <img src={`http://resource.ewe.rs/products/${item.acProduct}_v.jpg`} className="img-fluid" alt="b2b" onError={(e) => addDefaultSrc(e)} />
        //             </Link>
        //             {(type == "relatedProducts" || type == "viewedProducts") &&
        //                 <div className="prodcut-badges-images-holder">
        //                     {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
        //                         <>
        //                             {item.acIconType != 0 && (
        //                                 <CatalogProductBadgeImage key={key} item={item} />
        //                             )}
        //                         </>
        //                     ))}
        //                 </div>
        //             }
        //         </div>

        //         <div className="slider-special-action-title">
        //             <div className="action-title-name">
        //                 <Link
        //                     onMouseMove={() => setMouseMoved(true)}
        //                     onMouseDown={() => setMouseMoved(false)}
        //                     onMouseUp={(e) => handleClick(`/${t('routes:proizvod')}/${item.anProductKey}`, e)}
        //                     title={item.acName}
        //                     target={target}
        //                     to={whereTo}
        //                 >
        //                     {item.acDept} {item.acName}
        //                 </Link>
        //             </div>
        //         </div>
        //         <div className="slider-special-action-detail-res">
        //             <div className="spec-action-ident">{item.acProduct}</div>
        //             {getUserInfo.userInfo != null && (
        //                 <div className="spec-action-res">Rezervisano: {item.anReserved}</div>
        //             )}
        //         </div>
        //         <div className="slider-special-action-detail-price">
        //             {getUserInfo.userInfo != null && (
        //                 <div className="spec-action-qty">Lager: {item.anStock}</div>
        //             )}
        //             {item.anOldPrice > 0 && (
        //                 <div className="spec-action-price-old">
        //                     <CurrencyFormat num={item.anOldPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : {getB2bSettings.siteSettings.acLogOutUserCurrency}}
        //                 </div>
        //             )}
        //         </div>
        //         <div className="slider-special-action-detail-price">
        //             <div className={priceClassName}>
        //                 <CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : {getB2bSettings.siteSettings.acLogOutUserCurrency}}
        //             </div>
        //         </div>
        //         {getUserInfo.userInfo != null ? (
        //             <div className="slider-special-action-button">
        //                 <div className="add-btn-spec-input-qty">
        //                     <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />
        //                 </div>
        //                 <div className="add-btn-spec-action">
        //                     <div className="btn btn-sm btn-secondary w-100">
        //                         <AddToCartButton qtyValue={qtyProduct} product={item} name="Dodaj" activeCart={activeCart} />
        //                     </div>
        //                 </div>
        //             </div>
        //         ) : (
        //             <div className="slider-special-action-button">
        //                 <Link className="w-100" to={`/${t('routes:proizvod')}/${item.anProductKey}`}>
        //                     <button className="btn btn-success w-100 btn-sm"><i className="fas fa-external-link-square-alt"></i> Detaljnije</button>
        //                 </Link>
        //             </div>
        //         )}
        //     </div>
        // </div>
    );

};

export default BlockActionSliderItem;
