import React, { useState, useEffect } from 'react'
// import Form from 'react-bootstrap/Form';
// import { toast } from 'react-toastify';
// import MessageInfoBox from '../components/other/MessageInfoBox';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from "../../css/EweGoCartRanking.module.css";
import { Link } from 'react-router-dom';
import { getBenefitRangList } from '../../redux/actions/otherActions';


const EweGoCartRanking = () => {

    const userInfo = useSelector((state) => state.userInfo);
    const [data, setData] = useState([]);
    const getBenefitList = useSelector((state) => state.getBenefitList.items)

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getBenefitRangList())
    }, []);
    // console.log(getBenefitList, "getBenefitList RANKING");
    // const sortedBenefitList = getBenefitList.sort((a, b) => b.anPoint - a.anPoint);

    // const rankingList = sortedBenefitList.map((item, index) => ({
    //     number: index + 1,
    //     alias: item.acLastName,
    // }));
    const sortedBenefitList = getBenefitList.length > 0
        ? getBenefitList.sort((a, b) => b.anPoint - a.anPoint)
        : [];

    const rankingList = sortedBenefitList.map((item, index) => ({
        number: index + 1,
        alias: item.acLastName,
    }));
    let globalIndex = 0;
    return (

        <>
            <div className={`${styles['custom-container-fluid']} ${styles['main-container']}`}>
                <img
                    className={`${styles['ewe-go-cart-img-top']}`}
                    src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                    alt="top-banner"
                />

                <div className={`row col-sm-12 ${styles['ewe-go-cart']}`}>
                    <img
                        className={`${styles['ewe-go-cart-img']}`}
                        src="https://resource.ewe.rs/media/ewe-go-cart/ranking/ranglista-naslov.png"
                        alt="logo"
                    />
                </div>
                <div className={`${styles['ewe-go-brzina1-holder']}`}>
                    <img
                        className={`${styles['ewe-go-brzina1-img']}`}
                        src="https://resource.ewe.rs/media/ewe-go-cart/ranking/ranglista-slicicazabrzinu.png"
                        alt="logo"
                    />
                </div>
                <div className={`${styles['ewe-go-brzina2-holder']}`}>
                    <img
                        className={`${styles['ewe-go-brzina2-img']}`}
                        src="https://resource.ewe.rs/media/ewe-go-cart/ranking/ranglista-slicicazabrzinu2.png"
                        alt="logo"
                    />
                </div>

                <div className={`col-sm-8 ${styles['ranking-list']}`}>
                    <div className="row d-flex">
                        <div className={`col-lg-4 ${styles['ranking-list-img-holder']} d-flex flex-column`}>
                            <div className=''>
                                {rankingList.slice(0, 17).map((item) => {
                                    globalIndex++;
                                    return (
                                        <div key={item.number} className={`${styles['ranking-item']}`}>
                                            <span className={`${styles['ranking-number']}`}>{item.number}</span>
                                            <span className={`${styles['ranking-alias']} ${globalIndex <= 35 ? styles['emphasize'] : ''}`}>{item.alias}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`col-lg-4 ${styles['ranking-list-img-holder']} d-flex flex-column`}>
                            <div className=''>
                                {rankingList.slice(17, 34).map((item) => {
                                    globalIndex++;
                                    return (
                                        <div key={item.number} className={`${styles['ranking-item']}`}>
                                            <span className={`${styles['ranking-number']}`}>{item.number}</span>
                                            <span className={`${styles['ranking-alias']} ${globalIndex <= 35 ? styles['emphasize'] : ''}`}>{item.alias}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`col-lg-4 ${styles['ranking-list-img-holder']} d-flex flex-column`}>
                            <div className=''>
                                {rankingList.slice(34, 50).map((item) => {
                                    globalIndex++;
                                    return (
                                        <div key={item.number} className={`${styles['ranking-item']}`}>
                                            <span className={`${styles['ranking-number']}`}>{item.number}</span>
                                            <span className={`${styles['ranking-alias']} ${globalIndex <= 35 ? styles['emphasize'] : ''}`}>{item.alias}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['ewe-go-carting-your-coins']}`} >
                    <Link to="/ewe-go-cart-profile">
                        <div className={`${styles['button-go-carting-your-coins']}`} title="Pogledajte listu stečenih bodova">Moji bodovi</div>
                    </Link>
                </div>

                <div className="row d-flex flex-row-reverse col-sm-12">
                    <div className="col-sm-4">
                        <img
                            className={`${styles['ewe-go-cart-formula2-img']}`}
                            src="https://resource.ewe.rs/media/ewe-go-cart/ranking/ranglista-formula2.png"
                            alt="formula-1"
                        />
                    </div>
                    <div className="col-sm-8">
                        <img
                            className={`${styles['ewe-go-cart-formula1-img']}`}
                            src="https://resource.ewe.rs/media/ewe-go-cart/ranking/ranglista-formula1.png"
                            alt="formula-2"
                        />
                    </div>
                </div>



                <img
                    className={`${styles['ewe-go-cart-img-bottom']}`}
                    src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                    alt="bottom-banner"
                />
            </div>
        </>
    )
}

export default EweGoCartRanking