import global from './global';
import SecureLS from 'secure-ls';

export function axiosinstance(attributes, port, functionName) {
  // var qs = require('qs');
  let ls = new SecureLS({ encodingType: 'aes' });
  // const encryptedSessionToken = localStorage.getItem('sessionToken') ? JSON.parse(localStorage.getItem('sessionToken')) : null;
  const encryptedSessionToken = ls.get('sessionToken') ? ls.get('sessionToken') : null;
  let getSessionToken = '';
  if (encryptedSessionToken === null) {
    getSessionToken = global.sessionToken;
  } else {
    getSessionToken = encryptedSessionToken
  }

  // const getSessionToken2 = localStorage.getItem('sessionToken') ? JSON.parse(localStorage.getItem('sessionToken')) : global.sessionToken; // ovo je bilo pre kriptovanja 23.02.2024.

  var data = JSON.stringify({
    "funInfo": {
      "name": functionName,
      attributes
    },
    "token": {
      "session": getSessionToken,
      "api_oauth": ""
    }
  });

  var config = {
    method: 'post',
    url: 'https://newb2b.ewe.rs:8775/decisionController/reciveConnectionRequest',
    // url: '/api', // endpoint koji cemo gadjati kada se osposobi reverse proxy
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return config;
};