import React from 'react';
import CurrencyFormat from '../other/CurrencyFormat';
import { currencyFormat } from "../../helperFunctions";

const DashboardBoxInfo = ({ data, user, item }) => {

  const detailItem = data.tmbeList;

  const title = detailItem.filter((state) => state.acMenuBlockElement === 'title');
  const value = detailItem.filter((state) => state.acMenuBlockElement === 'valueFromSubjectInfo');
  const icon = detailItem.filter((state) => state.acMenuBlockElement === 'icon');
  const iconInverse = detailItem.filter((state) => state.acMenuBlockElement === 'iconInverse');

  if (!title[0]?.acMenuBlockElementValue || !value[0]?.acMenuBlockElementValue) {
    return null;
  }

  return (
    <div
      className={`dashboard-box-info ${title[0]?.acMenuBlockElementValue === 'Van valute:' &&
        `color-background-user-${user?.companyAdditionalInfo?.acCompanyFinancialStatus?.trim().toUpperCase()}`}`}
      style={{ borderBottom: `5px solid ${data?.acMenuBlockColor || '#000'}` }}
    >
      {Object.keys(icon).length !== 0 || Object.keys(iconInverse).length !== 0 ? (
        <div className="dashboard-box-info-icon">
          <div
            className={
              Object.keys(iconInverse).length !== 0
                ? 'icon-dashboard-info-inverse icon-dashboard-info'
                : 'icon-dashboard-info'
            }
            style={
              Object.keys(icon).length !== 0
                ? { fontSize: icon[0]?.acMenuBlockElementSize || '16px' }
                : Object.keys(iconInverse).length !== 0 && {
                  fontSize: iconInverse[0]?.acMenuBlockElementSize || '16px',
                }
            }
          >
            {Object.keys(icon).length !== 0 && (
              <div
                style={{ color: icon[0]?.acMenuBlockElementColor || '#000' }}
                dangerouslySetInnerHTML={{ __html: icon[0]?.acMenuBlockElementValue || '' }}
              />
            )}
            {Object.keys(iconInverse).length !== 0 && (
              <div dangerouslySetInnerHTML={{ __html: iconInverse[0]?.acMenuBlockElementValue || '' }} />
            )}
          </div>
        </div>
      ) : null}

      <div className="dashboard-box-info-text">
        <div className="dashboard-box-info-name" style={{ fontSize: title[0]?.acMenuBlockElementSize || '16px' }}>
          {title[0]?.acMenuBlockElementValue || ''}
        </div>
        <div className="dashboard-box-info-value" style={{ fontSize: value[0]?.acMenuBlockElementSize || '16px' }}>
          {currencyFormat(parseFloat(value[0]?.acMenuBlockElementValue) || 0)}
        </div>
      </div>
    </div>
  );
};

export default DashboardBoxInfo;
