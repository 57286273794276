import React from 'react';
import Countdown from 'react-countdown';

const DashboardBoxDelivery = ({ data }) => {

    const detailItem = data.tmbeList;

    const title = detailItem.filter((state) => state.acMenuBlockElement === 'title');
    const subtitle = detailItem.filter((state) => state.acMenuBlockElement === 'subtitle');
    const date = detailItem.filter((state) => state.acMenuBlockElement === 'date');
    const shortDescription = detailItem.filter((state) => state.acMenuBlockElement === 'shortDescription');

    if (!date[0]?.acMenuBlockElementValue) {
        return null;
    }

    var today = new Date();
    var countdown = date[0].acMenuBlockElementValue; // e.g., "16:30"
    countdown = countdown.split(':');

    var countdownHour = countdown[0];
    var countdownMinutes = countdown[1];

    var currentHour = today.getHours();
    var currentMinutes = today.getMinutes();

    if (currentHour < countdownHour) {
        today.setDate(today.getDate());
    } else if (currentHour === countdownHour) {
        if (currentMinutes < countdownMinutes) {
            today.setDate(today.getDate());
        } else {
            today.setDate(today.getDate() + 1);
        }
    } else {
        today.setDate(today.getDate() + 1);
    }
    today.setHours(countdownHour, countdownMinutes, 0);

    const ttoday = today.getTime();

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <div>00:00:00</div>;
        } else {
            return (
                <div>
                    {hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
                </div>
            );
        }
    };

    return (
        <div className="dashboard-box-delivery" style={{ borderBottom: `5px solid #e91e63` }}>
            {title[0]?.acMenuBlockElementValue && (
                <div className="dashboard-box-deliver-title" style={{ borderBottom: `1px solid ${data.acMenuBlockColor}` }}>
                    {title[0]?.acMenuBlockElementValue}
                </div>
            )}
            {subtitle[0]?.acMenuBlockElementValue && (
                <div className="dashboard-box-deliver-subtitle">
                    {subtitle[0]?.acMenuBlockElementValue}
                </div>
            )}
            <div className="dashboard-box-deliver-value">
                <Countdown date={ttoday} renderer={renderer} />
            </div>
            {shortDescription[0]?.acMenuBlockElementValue && (
                <div className="dashboard-box-deliver-info-text">
                    {shortDescription[0]?.acMenuBlockElementValue}
                </div>
            )}
        </div>
    );
};

export default DashboardBoxDelivery;
