import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
// import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import MessageInfoBox from '../other/MessageInfoBox';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ModalForRegistration = ({ show, handleClose }) => {
    const [isValidFirstName, setIsValidFirstName] = useState(0);
    const [isValidLastName, setIsValidLastName] = useState(0);
    const [isValidPhone, setIsValidPhone] = useState(0);
    const [isValidEmail, setIsValidEmail] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const { t } = useTranslation('modal');

    const dispatch = useDispatch();
    const onSubmitRegistration = (e) => {
        e.preventDefault();
    }
    // const [formData, setFormData] = useState({
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     phone: '',
    //     msg: '',
    // });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    // const handleSubmit = () => {
    //     console.log(formData);
    //     //reset forme
    //     setFormData({
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         phone: '',
    //         msg: '',
    //     });
    //     handleClose();
    // };
    const handleInput = () => {

        if (firstName == '') {
            setIsValidFirstName(1)
        } else {
            setIsValidFirstName(2)
        }
        if (lastName == '') {
            setIsValidLastName(1)
        } else {
            setIsValidLastName(2)
        }
        if (phone == '') {
            setIsValidPhone(1)
        } else {
            setIsValidPhone(2)
        }
        if (email == '') {
            setIsValidEmail(1)
        } else {
            if (validateEmail(email)) {
                setIsValidEmail(2)
            } else {
                setIsValidEmail(1)
            }
        }
        if (isValidFirstName != 2) {
            toast(<MessageInfoBox message="errorMissingOneOrMoreFields" type="0" />, {
                className: 'red-background',
            });
        }
        else if (isValidLastName != 2) {
            toast(<MessageInfoBox message="surnameNotFoundError" type="0" />, {
                className: 'red-background',
            });
        }
        else if (isValidEmail != 2) {
            toast(<MessageInfoBox message="emailNotFoundError" type="0" />, {
                className: 'red-background',
            });
        }
        else if (isValidPhone != 2) {
            toast(<MessageInfoBox message="phoneNotFoundError" type="0" />, {
                className: 'red-background',
            });
        } else {
            var values = {
                'name': firstName + ' ' + lastName,
                'email': email,
                'phone': phone,
                // 'acType': "EweSki2023",
            }
            if (msg !== '') {
                values.text = msg;
            }
            // dispatch(eweSkiRegistration(values)).then(res => {
            //     if (res.isOk == 1) {
            //         setIsRegistered(1);
            //     } else {
            //         setIsRegistered(2);
            //     }
            // }
            // )
            axios({
                method: "POST",
                url: "https://newb2b.ewe.rs:5000/product-contact",
                // url: "http://localhost:5000/product-contact",

                data: values
            }).then(function (response) {
                if (response.data.isOk == 1) {
                    handleClose();
                }
                toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                    className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                });
                // console.log(response, "res");
            }).catch(function (error) {
                console.log(error);
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });


        }
        // console.log('Ime:', firstName);
        // console.log('Prezime:', lastName);
        // console.log('Email:', email);
        // console.log('Kontakt telefon:', phone);
        // console.log('Upit:', msg);
        // console.log('data:', values);
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const onChangeInput = (e) => {
        // console.log(e.target.checked, 'cekirano')
        if (e.target.name == 'inputFirstName' && e.target.value == '') {
            setFirstName('')
        } else if (e.target.name == 'inputFirstName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                setFirstName(e.target.value)
            } else {
                setFirstName(firstName)
                toast(<MessageInfoBox message="validationErrorNumberExist" type="0" />, {
                    className: 'red-background',
                });
            }
        }
        if (e.target.name == 'inputLastName' && e.target.value == '') {
            setLastName('')
        } else if (e.target.name == 'inputLastName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                setLastName(e.target.value)
            } else {
                setLastName(lastName)
                toast(<MessageInfoBox message="validationErrorNumberExistSurname" type="0" />, {
                    className: 'red-background',
                });
            }
        }
        if (e.target.name == 'inputEmail' && e.target.value == '') {
            setEmail('')
        } else if (e.target.name == 'inputEmail' && e.target.value != '') {
            setEmail(e.target.value)
        }
        if (e.target.name == 'inputPhone') {
            if (e.target.value === '') {
                setPhone('');
            } else if (/^\d+$/.test(e.target.value)) {
                setPhone(e.target.value);
            } else {
                setPhone(phone);
                toast(<MessageInfoBox message="validationErrorLetterExistPhone" type="0" />, {
                    className: 'red-background',
                });
            }
        }
    }
    const onFocusOut = (e) => {

        if (e.target.name == 'inputFirstName' && e.target.value == '') {
            setIsValidFirstName(1)
        } else if (e.target.name == 'inputFirstName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                setIsValidFirstName(2)
            } else {
                toast(<MessageInfoBox message="validationErrorNumberExist" type="0" />, {
                    className: 'red-background',
                });
            }
        }
        if (e.target.name == 'inputLastName' && e.target.value == '') {
            setIsValidLastName(1)
        } else if (e.target.name == 'inputLastName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                setIsValidLastName(2)
            } else {
                toast(<MessageInfoBox message="validationErrorNumberExist" type="0" />, {
                    className: 'red-background',
                });
            }
        }
        if (e.target.name == 'inputEmail' && e.target.value == '') {
            setIsValidEmail(1)
        } else if (e.target.name == 'inputEmail' && e.target.value != '') {
            if (validateEmail(e.target.value)) {
                setIsValidEmail(2)
            } else {
                setIsValidEmail(1)
            }
        }
        if (e.target.name == 'inputPhone' && e.target.value == '') {
            setIsValidPhone(1)
        } else if (e.target.name == 'inputPhone' && e.target.value != '') {
            setIsValidPhone(2)

        }
    }
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:~`\s@\"]+(\.[^<>()[\]\\.,;:~`\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const hasNumber = (str) => {
        return /\d/.test(str);
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{t('kontaktForma')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>{t('unesiteSvojePodatke')}</p>
                </div>
                <div className="form-group">
                    <label>{t('ime')}</label>
                    <input
                        type="text"
                        name="inputFirstName"
                        onChange={(e) => { onChangeInput(e) }}
                        onBlur={(e) => { onFocusOut(e) }}
                        isInvalid={isValidFirstName == 1}
                        isValid={isValidFirstName == 2}
                        value={firstName}
                        autoComplete="off"
                        className={`form-control input-with-shadow ${isValidFirstName === 1 ? 'is-invalid' : isValidFirstName === 2 ? 'is-valid' : ''}`}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{t('prezime')}</label>
                    <input
                        type="text"
                        name="inputLastName"
                        className={`form-control input-with-shadow ${isValidLastName === 1 ? 'is-invalid' : isValidLastName === 2 ? 'is-valid' : ''}`}
                        onChange={(e) => { onChangeInput(e) }}
                        onBlur={(e) => { onFocusOut(e) }}
                        isInvalid={isValidLastName == 1}
                        isValid={isValidLastName == 2}
                        value={lastName}
                        autoComplete="off"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{t('mail')} {t('adresa')}</label>
                    <input
                        type="email"
                        name="inputEmail"
                        // value={formData.email}
                        // onChange={handleChange}
                        className={`form-control input-with-shadow ${isValidEmail === 1 ? 'is-invalid' : isValidEmail === 2 ? 'is-valid' : ''}`}
                        onChange={(e) => { onChangeInput(e) }}
                        onBlur={(e) => { onFocusOut(e) }}
                        isInvalid={isValidEmail == 1}
                        isValid={isValidEmail == 2}
                        value={email}
                        autoComplete="off"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{t('kontaktTelefon')}</label>
                    <input
                        type="number"
                        name="inputPhone"
                        // value={formData.phone}
                        // onChange={handleChange}
                        className={`form-control input-with-shadow ${isValidPhone === 1 ? 'is-invalid' : isValidPhone === 2 ? 'is-valid' : ''}`}
                        onChange={(e) => { onChangeInput(e) }}
                        onBlur={(e) => { onFocusOut(e) }}
                        isInvalid={isValidPhone == 1}
                        isValid={isValidPhone == 2}
                        value={phone}
                        autoComplete="off"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{t('upit')}</label>
                    <textarea
                        name="msg"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        className="form-control input-with-shadow"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={handleInput}>
                    {t('posalji')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalForRegistration;