import React from 'react';

import CurrencyFormat from '../other/CurrencyFormat';
import { useTranslation } from 'react-i18next';

const LoyaltyBox = ({ data }) => {

    const detailItem = data.tmbiList;
    const { t } = useTranslation();

    // console.log(detailItem, 'detailItem')

    return (
        <div className="dashboard-item-loaylty-content">
            <div className="dashboard-img-ly-title">
                <img src={process.env.PUBLIC_URL + (data?.acMenuBlockGroupImage || '')} alt="loyalty" className="img-fluid" />
                <div className="line-ly-separate"></div>
            </div>
            <div className="dashboard-loyalty-items">
                {detailItem.map((item, key) => {
                    if (item.anMenuBlockPosition === 1 && item.tmbeList[0]?.acMenuBlockElementValue) {
                        return (
                            <div className="dashboadr-item-loyalty" key={key}>
                                <div className="dashboard-item-loyalty-title" style={{ color: item.tmbeList[0]?.acMenuBlockElementColor || '#000' }}>
                                    {item.tmbeList[0]?.acMenuBlockElementValue || ''}
                                </div>
                                <div className="dashboard-item-loyalty-value-big">
                                    {item.tmbeList[1]?.acMenuBlockElementValue == 0 ? (
                                        <>
                                            <i className="fas fa-check"></i>
                                            <div className="dashboard-item-loyalty-desc">{t('mainModal:"ostvarenCilj')}</div>
                                        </>
                                    ) : (
                                        <div style={{ color: item.tmbeList[1]?.acMenuBlockElementColor || '#000' }}>
                                            {item.tmbeList[1]?.acMenuBlockElementValue || 0}%
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    } else if (item.anMenuBlockPosition === 2 && item.tmbeList[0]?.acMenuBlockElementValue) {
                        return (
                            <div className="dashboadr-item-loyalty trend-dashboard-content" key={key}>
                                <div className="dashboard-item-loyalty-title" style={{ color: item.tmbeList[0]?.acMenuBlockElementColor || '#000' }}>
                                    {item.tmbeList[0]?.acMenuBlockElementValue || ''}
                                </div>
                                <div className="dashboard-item-loyatly-per" style={{ color: item.tmbeList[1]?.acMenuBlockElementColor || '#000' }}>
                                    {item.tmbeList[1]?.acMenuBlockElementValue || 0}%
                                </div>
                                <div className="dashboard-item-loyalty-desc" style={{ color: item.tmbeList[2]?.acMenuBlockElementColor || '#000' }}>
                                    {item.tmbeList[2]?.acMenuBlockElementValue || ''}
                                </div>
                            </div>
                        );
                    } else if (item.anMenuBlockPosition === 3 && item.tmbeList[0]?.acMenuBlockElementValue) {
                        return (
                            <div className="dashboadr-item-loyalty" key={key}>
                                <div className="dashboard-loyalty-target">
                                    <div className="dashboard-ly-name" style={{ color: item.tmbeList[0]?.acMenuBlockElementColor || '#000' }}>
                                        {item.tmbeList[0]?.acMenuBlockElementValue || ''}
                                    </div>
                                    <div className="dashboard-ly-value" style={{ color: item.tmbeList[1]?.acMenuBlockElementColor || '#000' }}>
                                        <CurrencyFormat num={parseInt(item.tmbeList[1]?.acMenuBlockElementValue) || 0} /> &euro;
                                    </div>
                                </div>
                                <div className="dashboard-loyalty-traffic">
                                    <div className="dashboard-ly-name" style={{ color: item.tmbeList[2]?.acMenuBlockElementColor || '#000' }}>
                                        {item.tmbeList[2]?.acMenuBlockElementValue || ''}
                                    </div>
                                    <div className="dashboard-ly-value" style={{ color: item.tmbeList[3]?.acMenuBlockElementColor || '#000' }}>
                                        <CurrencyFormat num={parseInt(item.tmbeList[3]?.acMenuBlockElementValue) || 0} /> &euro;
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default LoyaltyBox;
