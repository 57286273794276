import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, Image, Font, StyleSheet } from "@react-pdf/renderer";
import CurrencyFormat from "../../other/CurrencyFormat";
import ProductSpecificationItemPdf from "./ProductSpecificationItemPdf";
import globalVariable from "../../../global";
import imageExists from 'image-exists';
import SecureLS from 'secure-ls';
import { useTranslation } from 'react-i18next';


const ProductDetailPdf = (props) => {

    let ls = new SecureLS({ encodingType: 'aes' });
    const { item, spec, currency, custom, productId, activeVat, badges2, acLogOutUserCurrency } = props;
    const userInfo = ls.get('userInfo');
    const [lastPage, setLastPage] = useState(1)
    const [badges, setBadges] = useState([])
    const { t } = useTranslation('customExportCatalog');
    // console.log(currency, "currency");

    Font.register({
        family: "Roboto",
        src: "/css/fonts/roboto/RobotoCondensed-Light.ttf",
    });
    Font.register({
        family: "Roboto-Bold",
        src: "/css/fonts/roboto/RobotoCondensed-Bold.ttf",
    });

    useEffect(() => {
        if (badges2 !== undefined) {
            badges2.forEach((item) => {
                imageExists(`https://resource.ewe.rs/attribute_values/${item}.png`, exist => {
                    if (exist) {
                        setBadges(badges => [...badges, item])
                    }
                })
            })
        }
    }, [badges2])

    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 30,
            paddingHorizontal: 10,
            width: 100,
            fontFamily: "Roboto",
            fontSize: 11,
            fontWeight: 200,
        },
        header: {
            paddingHorizontal: "0.5cm",
            marginBottom: 30,
        },
        headerTable: {
            borderBottomStyle: "dotted",
        },

        table: {
            display: "table",
            width: "150",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            marginBottom: 10,
            width: "auto",
        },
        tableRow: { flexDirection: "row" },
        tableCol: {
            width: "50%",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 2,
            borderColor: "#ccc",
        },

        tableCellLeft: {
            textAlign: "left",
        },
        tableCellRight: {
            textAlign: "right",
        },
        tableCellHeader: { margin: "auto", marginTop: 5, fontSize: 10 },
        pageNumber: {
            position: "absolute",
            fontSize: 12,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
        },
        footerImg: {
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            paddingTop: 10,
            borderTop: 1,
            borderTopStyle: "dotted",
            borderColor: "#ccc",
        },
        title: { fontSize: 18, marginBottom: 20, marginLeft: 25, fontFamily: "Roboto-Bold" },
    });

    return (
        <Document debug="true">
            <Page size="A4" style={styles.body} wrap>
                {productId ?
                    <Image src={t('srcMemo')} style={{ marginBottom: 15 }} />
                    :
                    userInfo && userInfo.company.acCompanyMemoHeader.trim() != "" &&
                    <Image src={`https://resource.ewe.rs/${userInfo.company.acCompanyMemoHeader}`} style={{ marginBottom: 15 }} />
                }
                <Text style={styles.title}>
                    {item.acDept.replace(" 1****", "")} {item.acName}
                </Text>

                <View style={[styles.tableRow, { marginVertical: 10 }]}>
                    <View style={{ width: "50%" }}>
                        <Image style={{ width: "80%", marginLeft: 20 }} src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}_v.jpg`} />
                    </View>
                    <View style={{ width: "250" }}>
                        <Text style={[styles.tableCellLeft, { paddingBottom: 3 }]}>{t('sifra')}: {item.acProduct}</Text>
                        {item.acDept && <Image style={{ width: 50, height: 50, marginVertical: 4 }} src={`${globalVariable.imagesPath}manufacturers/${item.acDept.replace(" 1****", "")}.png`} />}
                        <View style={styles.table}>
                            {item.anOldPrice != 0 && !custom && (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text>{t('staraCena')}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellRight}>
                                                <CurrencyFormat num={item.anOldPrice}></CurrencyFormat> {item.anOldPrice != 0 && currency}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text>{t("popust")}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellRight}>
                                                <CurrencyFormat num={item.anDiscount}></CurrencyFormat> {item.anDiscount != 0 && '%'}
                                            </Text>
                                        </View>
                                    </View>
                                </>
                            )}
                            {item.anRecommendedRetailPrice != 0 && !custom && (
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text>{t('preporucenaMpCena')}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellRight}>
                                            <CurrencyFormat num={item.anRecommendedRetailPrice}></CurrencyFormat> {item.anRecommendedRetailPrice != 0 && t('RSD')}
                                        </Text>
                                    </View>
                                </View>
                            )}

                            {userInfo && userInfo.isOk != 0 ? (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellLeft}>{t("cena")}:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellRight}>
                                                <CurrencyFormat num={custom ? custom.price : item.anPrice}></CurrencyFormat> {custom ? (custom.price != 0 && currency) : (item.anPrice != 0 && currency)}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellLeft}>{t('lager')}:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellRight}>{item.anStock}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellLeft}>{t('rezervisano')}:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellRight}>{item.anReserved}</Text>
                                        </View>
                                    </View>
                                </>
                            ) : null}
                        </View>

                        <Text style={[styles.tableCellRight]}>{userInfo && userInfo.isOk && !custom ? t('CenaSaRabatom') : t('Cena')} {currency} {activeVat == 1 ? t('saPdv') : t('bezPdv')} </Text>

                        <Text style={[styles.tableCellRight, { fontSize: 24, color: "#ff5500" }]}>
                            {userInfo && userInfo.isOk ? (
                                <>
                                    <CurrencyFormat num={custom ? custom.price : item.anPrice} /> {custom ? (custom.price != 0 && currency) : (item.anPrice != 0 && currency)}
                                </>
                            ) : (
                                <>
                                    <CurrencyFormat num={item.anRetailPrice} /> {item.anRetailPrice != 0 && (acLogOutUserCurrency ? acLogOutUserCurrency : t('RSD'))}
                                </>
                            )}
                        </Text>
                    </View>
                </View>
                <View style={[styles.tableRow, { flexWrap: "wrap", width: "100%", marginBottom: "20px" }]}>
                    {badges && badges.map((icon, key) => (
                        <Image key={key} style={[styles.tableCellRight, { height: "40px", width: "40px", marginLeft: 10, marginBottom: 10 }]} src={`https://resource.ewe.rs/attribute_values/${icon}.png`} />
                    ))}
                </View>

                <View style={styles.table}>
                    {spec.map((item, key) => (
                        <ProductSpecificationItemPdf key={key} name={item.acGroupName} desc={item.filterSet} />
                    ))}
                </View>
                <View render={({ pageNumber, totalPages }) => {
                    totalPages > lastPage && setLastPage(totalPages)
                }} />
                {!productId && userInfo && userInfo.company.acCompanyMemoFooter.trim() != "" && lastPage > 1 &&
                    <Image src={`https://resource.ewe.rs/${userInfo.company.acCompanyMemoFooter}`} style={styles.footerImg} />
                }
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            </Page>
        </Document>
    );
};

export default ProductDetailPdf;
