import { useState } from 'react';
import { ActionTypes } from '../contants/action-types';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { searchLetters } from '../../configSite';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';
import SecureLS from 'secure-ls';
import { setItem, getItem, clearStore } from '../../indexedDB';
import CryptoJS from 'crypto-js';

let ls = new SecureLS({ encodingType: 'aes' });

export const selectedProduct = (product) => {
    return {
        type: ActionTypes.SELECTED_PRODUCT,
        payload: product
    }
}

export const removeSelectedProduct = () => {
    return {
        type: ActionTypes.REMOVE_SELECTED_PRODUCT
    }
}

export const addProductCompare = (product) => async (dispatch, getState) => {


    try {
        const attributes = {};
        attributes["anProductKey"] = product.anProductKey;
        const configRes = axiosinstance(attributes, "8224", "productSpecification");

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {

                    product['spec'] = response.data.response;

                    dispatch({
                        type: "SET_PRODUCT_SPECIFICATION_COMPARE",
                        payload: response.data.response,
                    });

                    var arr = JSON.parse(localStorage.getItem("compareItems") || "[]");
                    arr.push(product);
                    localStorage.setItem("compareItems", JSON.stringify(arr));

                    dispatch({
                        type: ActionTypes.PRODUCT_ADD_COMPARE,
                        payload: product,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    } catch (error) { }



}

// export const removeProductCompare = (product) => async (dispatch) => {
//     console.log(product, 'remove123');
//     const productId = product.anProductKey;
//     console.log(JSON.parse(localStorage.getItem("compareItems")), ' JSON.parse(localStorage.getItem("compareItems"))');

//     let items = JSON.parse(localStorage.getItem("compareItems"));
//     console.log(items, "items1");
//     console.log(productId, "productId");

//     items = items.filter((item) => item._id !== product._id);
//     console.log(items, "items");
//     localStorage.removeItem("compareItems");
//     localStorage.setItem("compareItems", JSON.stringify(items));
//     console.log(product, items, 'itm product u acti');

// dispatch({
//     type: ActionTypes.PRODUCT_REMOVE_COMPARE,
//     payload: product
// });
// }
export const removeProductCompare = (product) => async (dispatch) => {
    // console.log(product, 'remove123');
    const productKey = product.anProductKey;
    var itm = JSON.parse(localStorage.getItem("compareItems"));
    // console.log(itm, 'itm');
    // console.log(JSON.parse(localStorage.getItem("compareItems")), ' JSON.parse(localStorage.getItem("compareItems"))');

    let items = JSON.parse(localStorage.getItem("compareItems"));
    // console.log(items, "items1");
    // console.log(productKey, "productKey");

    items = items.filter((item) => item.anProductKey !== productKey);
    // console.log(items, "items");
    localStorage.removeItem("compareItems");
    localStorage.setItem("compareItems", JSON.stringify(items));
    // console.log(product, items, 'itm product u acti');

    dispatch({
        type: ActionTypes.PRODUCT_REMOVE_COMPARE,
        payload: product
    });
}



export const removeProductCompareAll = () => async (dispatch, getState) => {

    localStorage.removeItem("compareItems");
    var arr = JSON.parse("[]");
    localStorage.setItem("compareItems", JSON.stringify(arr));

    dispatch({
        type: ActionTypes.PRODUCT_REMOVE_COMPARE_ALL
    });
};

export const setCatalogList = (list) => {
    return {
        type: ActionTypes.SET_CATALOG_LIST,
        payload: list
    }
}

export const removeCatalogList = () => {
    return {
        type: ActionTypes.REMOVE_CATALOG_LIST
    }
}


// export const setSearchProducts = (products) => {
//     return {
//         type: ActionTypes.SET_SEARCH_PRODUCTS,
//         payload: products
//     }
// }

export const removeSetSearchProducts = () => {
    return {
        type: ActionTypes.REMOVE_SEARCH_PRODUCTS
    }
}
/*
export const fetchPosts = (categoryId, catagoryType, anId = 0, sorting = 1) => async dispatch => {
    // console.log('NEMANJA')
    dispatch({
        type: 'SET_CATALOG_ITEMS_REQUEST',
    });

    try {

        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));
        const attributes = {};


        attributes['anMainCategoryId'] = catagoryType == 'kategorija' ? categoryId : 0;
        attributes['anCategoryId'] = catagoryType == 'podkategorija' ? categoryId : 0;
        attributes['anSubCategoryId'] = catagoryType == 'podpodkategorija' ? categoryId : 0;
        attributes['acBrand'] = catagoryType == 'brend' ? categoryId : '';
        attributes['anId'] = anId;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
        attributes['isPdv'] = activeVat ? activeVat : 0;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['anCatalogSortingType'] = sorting;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        // console.log(attributes, 'attributes')
        const configRes = axiosinstance(attributes, '8224', 'getProduct');

        axios(configRes)
            .then(function (response) {
                console.log(response.data);
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_CATALOG_ITEMS',
                        payload: response.data.response.product
                    });
                    dispatch({
                        type: 'SET_FILTERS',
                        payload: response.data.response.tempFilterList == null ? [] : response.data.response.tempFilterList
                    });
                    dispatch({
                        type: 'SET_FILTERS_BRANDS',
                        payload: response.data.response.brandList == null ? [] : response.data.response.brandList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
*/
export const getLoyaltyProducts = (type) => async dispatch => {

    try {
        if (!type) {
            type = 1;
        }
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anLoyaltyTypeId'] = type;
        const configRes = axiosinstance(attributes, '8225', 'postCompanyLoyalityShop');

        axios(configRes)
            .then(function (response) {
                // console.log(response, 'response') 
                dispatch({
                    type: 'SET_LOYALTY_PRODUCTS',
                    payload: response.data.response
                });

            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const getLoyaltySettings = (type) => async dispatch => {

    try {
        if (!type) {
            type = 1;
        }
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anLoyaltyTypeId'] = type;
        const configRes = axiosinstance(attributes, '8225', 'postCompanyLoyalitySettings');

        axios(configRes)
            .then(function (response) {
                dispatch({
                    type: 'SET_LOYALTY_SETTINGS',
                    payload: response.data.response.companyLoyalitySettings[0]
                });

            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};


export const getProductDetail = (anId) => async dispatch => {

    dispatch({
        type: 'SET_PRODUCT_DETAIL_REQUEST',
    });

    try {

        var userInfoData = ls.get('userInfo');
        var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));
        // console.log(userInfoData, 'userInfoData')
        const attributes = {};
        attributes['anMainCategoryId'] = 0;
        attributes['anCategoryId'] = 0;
        attributes['anSubCategoryId'] = 0;
        attributes['acBrand'] = '';
        attributes['anId'] = anId.trim() !== "" && anId !== undefined && anId !== null ? anId : 0;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['anCatalogSortingType'] = 1;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'getProduct');


        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    if (response.data.response != null) {
                        if (response.data.response.product.length > 0) {
                            let cat = response.data.response.product[0].anSubCategoryKey;
                            attributes["anSubCategoryId"] = cat;
                            attributes["anId"] = 0;
                            // console.log(attributes, 'attributes123')
                            const configRes = axiosinstance(attributes, "8224", "getProduct");

                            axios(configRes)
                                .then(function (response) {

                                    // console.log(response.data.response.product, 'ssssss');
                                    if (response.data.isOk == 1) {
                                        let filterIt = response.data.response.product.filter((item) => {
                                            if (item.anProductId != anId) return true;
                                        });

                                        dispatch({
                                            type: "SET_RELATED_CATEGORY_ITEMS",
                                            payload: filterIt,
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                        dispatch({
                            type: 'SET_PRODUCT_DETAIL',
                            payload: response.data.response.product
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {

    }
};
export const getProductDetailForPopup = (anId) => async dispatch => {

    dispatch({
        type: 'SET_PRODUCT_DETAIL_REQUEST',
    });

    try {

        var userInfoData = ls.get('userInfo');
        var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
        var activeCurrency = {
            "_id": 1,
            "acName": "Evri",
            "acShortName": "EUR"
        }
        var activeVat = 0;
        // console.log(userInfoData, 'userInfoData')
        const attributes = {};
        attributes['anMainCategoryId'] = 0;
        attributes['anCategoryId'] = 0;
        attributes['anSubCategoryId'] = 0;
        attributes['acBrand'] = '';
        attributes['anId'] = anId.trim() !== "" && anId !== undefined && anId !== null ? anId : 0;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['anCatalogSortingType'] = 1;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'getProduct');


        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    if (response.data.response != null) {
                        if (response.data.response.product.length > 0) {
                            let cat = response.data.response.product[0].anSubCategoryKey;
                            attributes["anSubCategoryId"] = cat;
                            attributes["anId"] = 0;
                            // console.log(attributes, 'attributes123')
                            const configRes = axiosinstance(attributes, "8224", "getProduct");

                            axios(configRes)
                                .then(function (response) {

                                    // console.log(response.data.response.product, 'ssssss');
                                    if (response.data.isOk == 1) {
                                        let filterIt = response.data.response.product.filter((item) => {
                                            if (item.anProductId != anId) return true;
                                        });

                                        dispatch({
                                            type: "SET_RELATED_CATEGORY_ITEMS",
                                            payload: filterIt,
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                        dispatch({
                            type: 'SET_PRODUCT_DETAIL',
                            payload: response.data.response.product
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {

    }
};


export const getProductSpecification = (anId) => async dispatch => {

    try {
        const attributes = {};
        attributes['anProductKey'] = anId;
        const configRes = axiosinstance(attributes, '8224', 'productSpecification');

        dispatch({
            type: 'SET_PRODUCT_SPECIFICATION',
            payload: []
        });
        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_PRODUCT_SPECIFICATION',
                        payload: response.data.response
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {

    }
};

export const fetchPostsProduct = (categoryId, catagoryType, anId = 0) => async dispatch => {

    var userInfoData = ls.get('userInfo');
    var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    var activeVat = JSON.parse(localStorage.getItem('activeVat'));
    // console.log(userInfoData, 'userInfoData')
    const attributes = {};
    attributes['anMainCategoryId'] = catagoryType == 'kategorija' ? categoryId : 0;
    attributes['anCategoryId'] = catagoryType == 'podkategorija' ? categoryId : 0;
    attributes['anSubCategoryId'] = catagoryType == 'podpodkategorija' ? categoryId : 0;
    attributes['acBrand'] = catagoryType == 'brend' ? categoryId : '';
    attributes['anId'] = anId.trim() !== "" && anId !== undefined && anId !== null ? anId : 0;
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
    attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
    attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
    attributes['anCatalogSortingType'] = 1;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    const configRes = axiosinstance(attributes, '8224', 'getProduct');

    axios(configRes)
        .then(function (response) {
            if (response.data.isOk == 1) {
                dispatch({
                    type: 'SET_BANNER_IDENT',
                    payload: response.data.response.product
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const addProductWishList = (product) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['anUserKey'] = userInfoData.company.department[0].user[0].anUserKey;
    attributes['anProductKey'] = product.anProductKey;
    const configRes = axiosinstance(attributes, '8224', 'addToWishList');

    axios(configRes)
        .then(function (response) {
            // dispatch({
            //     type: 'SET_MESSAGE_INFO',
            //     payload: response.data
            // });
            // if(response.data.isOk == 1) {
            // }

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const removeProductWishList = (product, isAll) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['anUserKey'] = userInfoData.company.department[0].user[0].anUserKey;
    attributes['anProductKey'] = product ? product.anProductKey : 0;
    attributes['isAll'] = isAll;
    const configRes = axiosinstance(attributes, '8224', 'removeFromWishList');

    axios(configRes)
        .then(function (response) {
            // dispatch({
            //     type: 'SET_MESSAGE_INFO',
            //     payload: response.data
            // });

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.isOk == 1) {
                dispatch({
                    type: 'REMOVE_FROM_WISH_LIST',
                    payload: product
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const removeProductWishListAll = (product, isAll) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['anUserKey'] = userInfoData.company.department[0].user[0].anUserKey;
    attributes['anProductKey'] = product ? product.anProductKey : 0;
    attributes['isAll'] = isAll;
    const configRes = axiosinstance(attributes, '8224', 'removeFromWishList');

    axios(configRes)
        .then(function (response) {
            // dispatch({
            //     type: 'SET_MESSAGE_INFO',
            //     payload: response.data
            // });

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.isOk == 1) {
                dispatch({
                    type: 'REMOVE_FROM_WISH_LIST_ALL',
                    payload: product
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const getWishList = () => async dispatch => {

    var userInfoData = ls.get('userInfo');
    var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
    attributes['isPdv'] = getUserSettings ? getUserSettings.isPriceWithVat : 1;
    attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
    const configRes = axiosinstance(attributes, '8224', 'getMyWishList');

    axios(configRes)
        .then(function (response) {
            if (response.data.isOk == 1) {
                dispatch({
                    type: 'SET_WISH_LIST',
                    payload: response.data.response.wishList
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

function groupByKey(array, key) {
    return array
        .reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
}

export const getFilteredProducts = (categoryId, catagoryType, acFilterTable, anPriceMin = 0, anPriceMax = 10000000) => async dispatch => {
    dispatch({ type: 'SET_CATALOG_ITEMS_REQUEST' }); // Postavite loading na true
    var filtersObject = acFilterTable && acFilterTable.split('&');
    var filtersArray = [];
    var brandItem = '';
    if (acFilterTable != '' && acFilterTable != undefined && acFilterTable != null) {
        filtersObject.forEach(item => {
            var filtersObjectItem = item.split('=');

            if (filtersObjectItem[0] == 'cena_0') {
                anPriceMin = filtersObjectItem[1].split('_')[0];
                anPriceMax = filtersObjectItem[1].split('_')[1];
            } else {
                var obj = {};
                obj['acAttributeURLName'] = filtersObjectItem[0].split('_')[0];
                obj['acUrlValue'] = filtersObjectItem[1].split('_')[0];
                obj['anAttribute'] = filtersObjectItem[0].split('_')[1];
                obj['anValue'] = filtersObjectItem[1].split('_')[1];

                filtersArray.push(obj);
            }
        });
    }

    var userInfoData = ls.get('userInfo');
    var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));
    var activeVat = JSON.parse(localStorage.getItem('activeVat'));

    const attributes = {};
    var webFiltersObj = {};
    var isLastValue = 0;

    filtersArray.forEach((item, index, array) => {

        if (index === array.length - 1) {
            isLastValue = 1;
        }

        if (!webFiltersObj[item.anAttribute]) {

            webFiltersObj[item.anAttribute] = {};
            webFiltersObj[item.anAttribute]["anGroup"] = item.anAttribute;
            webFiltersObj[item.anAttribute]["webFilterList"] = [];
            webFiltersObj[item.anAttribute]["webFilterList"].push({
                anAttribute: item.anAttribute,
                anValue: item.anValue,
                isLast: isLastValue
            });
        } else {
            webFiltersObj[item.anAttribute]["webFilterList"].push({
                anAttribute: item.anAttribute,
                anValue: item.anValue,
                isLast: isLastValue
            });
        }
    });

    attributes['anCategoryId'] = catagoryType == 'podkategorija' ? categoryId : 0;
    attributes['anSubCategoryId'] = catagoryType == 'podpodkategorija' ? categoryId : 0;
    attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
    attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
    attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
    // attributes['acBrand'] = brandItem;
    attributes['acFilterTable'] = Object.values(webFiltersObj);
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    attributes['anCatalogSortingType'] = activeProductSort ? activeProductSort : 1;

    attributes['anPriceMin'] = anPriceMin;
    attributes['anPriceMax'] = anPriceMax;
    attributes['acIdents'] = "";

    attributes['acIdents'] = '';

    // dispatch({
    //     type: 'SET_CATALOG_ITEMS_REQUEST',
    // });

    const configRes = axiosinstance(attributes, '8224', 'filterProduct');

    axios(configRes)
        .then(async function (response) {
            localStorage.setItem("priceCatalog", JSON.stringify({
                anPriceFilterMax: response.data.response.anPriceFilterMax,
                anPriceFilterMin: response.data.response.anPriceFilterMin,
            }));

            dispatch({
                type: 'SET_CATALOG_ITEMS',
                payload: response.data.response.product
            });
            dispatch({
                type: 'SET_FILTERS',
                payload: response.data.response.tempFilterList == null ? [] : response.data.response.tempFilterList
            });
            dispatch({
                type: 'SET_FILTERS_BRANDS',
                payload: response.data.response.brandList == null ? [] : response.data.response.brandList
            });
            dispatch({
                type: 'SET_FILTERS_SPECIAL_ACTION',
                payload: response.data.response.specialList == null ? [] : response.data.response.specialList
            });

            dispatch({
                type: 'SET_FILTERS_PRICE',
                payload: {
                    anPriceFilterMax: response.data.response.anPriceFilterMax,
                    anPriceFilterMin: response.data.response.anPriceFilterMin,
                }
            });
            dispatch({
                type: 'SET_FINISHED_FILTERS',
                payload: true
            });
            // console.log(userInfoData.company.department[0].user[0].userSettings[0].anMargin, 'userInfoData.company.department[0].user[0].userSettings[0].anMargin')
            if (userInfoData) {
                response.data.response.product.forEach(element => {
                    element.anPricePdf = element.anPrice + (element.anPrice * userInfoData.company.department[0].user[0].userSettings[0].anMargin / 100);
                });
                // ls.set('catalogItems', response.data.response.product);
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(response.data.response.product), 'Č1k1n@T@jn@').toString();
                await setItem('catalogItems', encryptedData);
            }
        })
        .catch(function (error) {
            console.error('error catch:', error);

            dispatch({ type: 'SET_CATALOG_ITEMS', payload: [] });
            // console.log(error);
        });
};

export const getSearchItemsPage = (term, brand, category) => async dispatch => {

    var userInfoData = ls.get('userInfo');
    var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    var activeVat = JSON.parse(localStorage.getItem('activeVat'));
    var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));

    const attributes = {};
    attributes['acTerm'] = searchLetters(term);
    attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
    attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
    attributes["acRebate"] = userInfoData ? userInfoData.company.acCompanyRebate : "E";
    attributes['anCatalogSortingType'] = activeProductSort ? activeProductSort : 1;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    attributes['anSubCategory'] = category ? category : 0;
    attributes['acBrand'] = brand ? brand : '';

    const configRes = axiosinstance(attributes, '8224', 'searchB2b');

    // dispatch({
    //     type: 'SET_SEARCH_PRODUCTS_REQUEST',
    // });

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_SEARCH_PRODUCTS',
                payload: response.data.response.productList
            });
            dispatch({
                type: 'SET_SEARCH_BRANDS',
                payload: response.data.response.brendList
            });
            dispatch({
                type: 'SET_SEARCH_CATEGORIES',
                payload: response.data.response.categoryList
            });
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: 'SET_SEARCH_PRODUCTS',
                payload: []
            });
            dispatch({
                type: 'SET_SEARCH_BRANDS',
                payload: []
            });
            dispatch({
                type: 'SET_SEARCH_CATEGORIES',
                payload: []
            });
        });

};

export const getSearchItemsBox = (q, type) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes["acTerm"] = searchLetters(q);
    attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    const configRes = axiosinstance(attributes, "8224", "searchFastB2b");

    dispatch({
        type: 'SEARCH_LIST_AUTOCOMPLETE_REQUEST',
        payload: {
            loading: true
        }
    });

    if (type == 'clear') {
        dispatch({
            type: 'SEARCH_LIST_AUTOCOMPLETE_CLEAR',
        });
    } else {

        axios(configRes)
            .then(function (response) {
                if (response.data.response.acMsg == 'fastSearchEmptySuccess') {
                    dispatch({
                        type: 'SEARCH_LIST_AUTOCOMPLETE_CLEAR',
                    });
                } else {
                    dispatch({
                        type: 'SEARCH_LIST_AUTOCOMPLETE',
                        payload: {
                            data: response.data.response,
                            loading: false
                        }
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export const getProductRelatedSale = (anId) => async (dispatch) => {
    let ls = new SecureLS({ encodingType: 'aes' });
    var userInfoData = ls.get('userInfo');
    var getUserSettings = JSON.parse(localStorage.getItem("getUserSettings"));
    var activeCurrency = JSON.parse(localStorage.getItem("activeCurrency"));
    var activeVat = JSON.parse(localStorage.getItem('activeVat'));

    const attributes = {};
    attributes["isEur"] = activeCurrency ? activeCurrency._id : 1;
    attributes["acRebate"] = userInfoData ? userInfoData.company.acCompanyRebate : "E";
    attributes["isPdv"] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
    attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes["anProductKey"] = anId;

    const configRes = axiosinstance(attributes, "8224", "productRelatedSale");

    axios(configRes)
        .then(function (response) {
            let relatedSale = response.data.response.setProductRelatedSaleList ? response.data.response.setProductRelatedSaleList : [];
            dispatch({
                type: "GET_RELATED_SALE",
                payload: relatedSale,
            });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const saveCustomOfferPdf = (offer) => async (dispatch) => {
    dispatch({
        type: "SET_CUSTOM_PDF",
        payload: offer,
    });
};

export const fetchWeeklyPosts = (filterId, type) => async dispatch => {
    dispatch({
        type: 'SET_WEEKLY_ITEMS_REQUEST',
    });

    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));
        var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));

        var webFiltersObj = {};
        webFiltersObj[99999] = {};
        webFiltersObj[99999]["anGroup"] = 1;
        webFiltersObj[99999]["webFilterList"] = [];
        webFiltersObj[99999]["webFilterList"].push({
            anAttribute: 1,
            anValue: 4,
            isLast: 1
        });
        attributes['acFilterTable'] = Object.values(webFiltersObj);

        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'C';
        attributes['anCatalogSortingType'] = activeProductSort ? activeProductSort : 1;
        attributes['anCategoryId'] = 99;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anSubCategoryId'] = 0;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['anPriceMin'] = 0;
        attributes['anPriceMax'] = 10000000;
        attributes['acIdents'] = '';
        const configRes = axiosinstance(attributes, '8224', 'filterProduct');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    if (type === 'weekAction') {
                        dispatch({
                            type: 'SET_WEEKLY_ITEMS',
                            payload: response.data.response.product
                        });
                    } else if (type === 'inStockAgain') {
                        dispatch({
                            type: 'SET_STOCK_AGAIN_ITEMS',
                            payload: response.data.response.product
                        });
                    } else if (type === 'recommendedForYou') {
                        dispatch({
                            type: 'SET_RECOMMENDED_ITEMS',
                            payload: response.data.response.product
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const getActionProductsList = (id = 0, type = null, idents = '') => async dispatch => {

    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));
        var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));

        var webFiltersObj = {};
        if (id != 0) {
            webFiltersObj[99999] = {};
            webFiltersObj[99999]["anGroup"] = 1;
            webFiltersObj[99999]["webFilterList"] = [];
            webFiltersObj[99999]["webFilterList"].push({
                anAttribute: 1,
                anValue: id,
                isLast: 1
            });
        }
        attributes['acFilterTable'] = idents != "" ? [] : Object.values(webFiltersObj);

        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'C';
        attributes['anCatalogSortingType'] = activeProductSort ? activeProductSort : 1;
        attributes['anCategoryId'] = 0;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anSubCategoryId'] = 0;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['anPriceMin'] = 0;
        attributes['anPriceMax'] = 10000000;
        attributes['acIdents'] = idents ? idents.toString() : "";
        const configRes = axiosinstance(attributes, '8224', 'filterProduct');

        axios(configRes)
            .then(async function (response) {
                if (response.data.isOk == 1) {
                    if (type === 'weekAction') {
                        dispatch({
                            type: 'SET_WEEKLY_ITEMS',
                            payload: response.data.response.product
                        });
                    } else if (type === 'inStockAgain') {
                        dispatch({
                            type: 'SET_STOCK_AGAIN_ITEMS',
                            payload: response.data.response.product
                        });
                    } else if (type === 'recommendedForYou') {
                        dispatch({
                            type: 'SET_RECOMMENDED_ITEMS',
                            payload: response.data.response.product
                        });
                    } else {
                        dispatch({
                            type: 'SET_SPECIAL_ACTION_PRODUCTS',
                            payload: response.data.response.product
                        });
                    }
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(response.data.response.product), 'Č1k1n@T@jn@').toString();
                    await setItem('catalogItems', encryptedData);
                    // ls.set('catalogItems', response.data.response.product);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const getLastProductsList = () => async dispatch => {

    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));

        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'C';
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;

        const configRes = axiosinstance(attributes, '8224', 'postLastVisitedProduct');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_LAST_PRODUCTS',
                        payload: response.data.response.lastVisited
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const getAuctionProducts = () => async dispatch => {

    try {
        const attributes = {};
        const configRes = axiosinstance(attributes, '8224', 'getConnectionAuction');

        axios(configRes)
            .then(function (response) {
                // console.log(response.data.response.auctionList, 'response')
                dispatch({
                    type: 'SET_AUCTION_PRODUCTS',
                    payload: response.data.response.auctionList
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const setAuctionItemBidFunction = (item, bidValue) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    try {
        const attributes = {};
        attributes['acAuctionKey'] = item.acAuctionKey;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['anBidPrice'] = bidValue;
        const configRes = axiosinstance(attributes, '8224', 'postAuctionBid');

        axios(configRes)
            .then(function (response) {
                if (response.data.response.isOk == 1) {
                    let notifyEmail = response.data.response.userToNotify.acEmail
                    let userId = response.data.response.userToNotify._id

                    const attributes = {};
                    const configRes = axiosinstance(attributes, '8224', 'getAuction');

                    axios(configRes)
                        .then(function (response) {
                            dispatch({
                                type: 'SET_AUCTION_PRODUCTS',
                                payload: response.data.response.auctionList
                            });

                            let auction = response.data.response.auctionList.filter(item2 => item2.acAuctionKey == item.acAuctionKey);
                            auction[0].anCurentPrice = auction[0].anCurentPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

                            axios({
                                method: "POST",
                                url: "https://newb2b.ewe.rs:5000/auction-notify",
                                data: {
                                    email: notifyEmail,
                                    product: auction[0],
                                    userId: userId
                                }
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const subscribeToAuction = (item) => async dispatch => {

    var userInfoData = ls.get('userInfo');

    try {
        const attributes = {};
        attributes['acAuctionKey'] = item.acAuctionKey;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['acEmail'] = userInfoData ? userInfoData.company.department[0].user[0].acEmail : "";
        attributes['isSubscibing'] = 1;

        const configRes = axiosinstance(attributes, '8224', 'subscribeToNotification');

        axios(configRes)
            .then(function (response) {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};

export const getPopupProductDetail = (anId) => async dispatch => {

    try {
        var userInfoData = ls.get('userInfo');
        var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
        var activeCurrency = {
            "_id": 1,
            "acName": "Evri",
            "acShortName": "EUR"
        }
        var activeVat = 1;
        // console.log(userInfoData, 'userInfoData')
        const attributes = {};
        attributes['anMainCategoryId'] = 0;
        attributes['anCategoryId'] = 0;
        attributes['anSubCategoryId'] = 0;
        attributes['acBrand'] = '';
        attributes['anId'] = anId.trim() !== "" && anId !== undefined && anId !== null ? anId : 0;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
        attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
        attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
        attributes['anCatalogSortingType'] = 1;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'getProduct');


        axios(configRes)
            .then(function (response) {
                dispatch({
                    type: 'SET_PRODUCT_DETAIL',
                    payload: response.data.response.product
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error);
    }
};