import React, { useState, useEffect } from 'react'
// import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from "../../css/EweGoCartLogin.module.css";
import { postInsertCoinUser } from '../../redux/actions/userActions';
import { getBenefitRangList } from '../../redux/actions/otherActions';

const EweGoCartLogin = () => {

    const userInfo = useSelector((state) => state.userInfo);
    const [formData, setFormData] = useState({
        company: '',
        name: '',
        email: '',
        alias: '',
    });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const [fullName, setFullName] = useState('');
    const [alias, setAlias] = useState('');

    const [isValidFullName, setIsValidFullName] = useState(0);
    const [isValidAlias, setIsValidAlias] = useState(0);
    const [isRegistered, setIsRegistered] = useState(0);
    const getBenefitList = useSelector((state) => state.getBenefitList.items);
    const anCompanyKey = useSelector((state) => state.userInfo.userInfo.company.anCompanyKey);
    // console.log(getBenefitList, "test benefitlist");

    useEffect(() => {
        dispatch(getBenefitRangList())
    }, [])

    // useEffect(() => {
    //     getBenefitList.forEach((item) => {
    //         if (item.anCompanyKey === anCompanyKey) {
    //             setIsRegistered(1)
    //             setFullName(item.acFirstName)
    //             setAlias(item.acLastName)
    //             // console.log(item, 'item')
    //         }
    //     })
    // }, [getBenefitList, anCompanyKey])

    const handleInput = () => {

        // if (company === '') {
        //     setIsValidCompany(1);
        // } else {
        //     setIsValidCompany(2);
        // }

        if (fullName === '') {
            setIsValidFullName(1);
        } else {
            setIsValidFullName(2);
        }

        // if (email === '') {
        //     setIsValidEmail(1);
        // } else {
        //     setIsValidEmail(2);
        // }

        if (alias === '') {
            setIsValidAlias(1);
        } else {
            setIsValidAlias(2);
        }

        if (isValidFullName !== 2 || isValidAlias !== 2) {
            toast(<MessageInfoBox message="errorMissingOneOrMoreFields" type="0" />, {
                className: 'red-background',
            });
        } else {
            let values = {
                'acFirstName': fullName,
                'acLastName': alias,
                'acAvatarUrl': ''
            };
            // console.log("Prikupljene vrednosti:", values);

            dispatch(postInsertCoinUser(values)).then(res => {
                if (res.isOk === 1) {
                    setIsRegistered(1);
                    setFullName('');
                    setAlias('');
                } else {
                    setIsRegistered(0);
                }
            });
        }
    };
    const onChangeInput = (e) => {
        const { name, value } = e.target;

        switch (name) {

            case 'fullName':
                if (value === '') {
                    setIsValidFullName(1);
                    setFullName('');
                } else {
                    setIsValidFullName(2);
                    setFullName(value);
                }
                break;

            case 'alias':
                if (value === '') {
                    setIsValidAlias(1);
                    setAlias('');
                } else {
                    setIsValidAlias(2);
                    setAlias(value);
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className={`${styles['custom-container-fluid']}`}>
                <img src="https://resource.ewe.rs//media/ewe-go-cart/login/login-traka.png" alt="Ewe Go Cart" className={`${styles['full-width-image']}`} />
                <img src="https://resource.ewe.rs/media/ewe-go-cart/login/login-formula.png" alt="Ewe Go Cart Formula" className={`${styles['login-formula']}`} />

                <div className={`container ${styles['ewe-go-cart-login-container']}`}>
                    <div className={`row ${styles['login-logo-title-holder']}`}>
                        <div className={`${styles['login-logo-holder']}`}><img src="https://resource.ewe.rs//media/ewe-go-cart/login/login-logo.png" alt="Ewe Go Cart Logo" className={`${styles['login-logo']}`} /></div>
                        <div className={`${styles['login-logo-title']}`}><h4>EMBRACE THE RACE</h4></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <img src="https://resource.ewe.rs/media/ewe-go-cart/login/login-naslov.png" alt="Ewe Go Cart" className={`${styles['login-title-image']}`} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <h1 className={`${styles['login-title']}`}>TRAŽIMO ŠAMPIONA</h1>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-md-12 text-center'>
                            <label className={`${styles['login-input-label']}`} htmlFor="login-input2">Ime i prezime:</label>
                            <input
                                type="text"
                                id="login-input2"
                                className={`${styles['login-custom-input']}`}
                                name="fullName"
                                value={fullName}
                                onChange={onChangeInput}
                            />
                        </div>
                    </div>
                    <div className={`${styles['login-second-input']} row`}>

                        <div className='col-md-12 text-center'>
                            <label className={`${styles['login-input-label']}`} htmlFor="login-input4">Alijas:</label>
                            <input
                                type="text"
                                id="login-input4"
                                className={`${styles['login-custom-input']}`}
                                name="alias"
                                value={alias}
                                onChange={onChangeInput}
                            />
                        </div>
                    </div>

                    <div className={`${styles['login-form-button-holder']} row`}>
                        <div className='col-sm-12 text-center'>
                            <button className={`${styles['login-form-button']}`} onClick={handleInput}>Pošalji prijavu</button>
                        </div>
                    </div>

                </div>


                <img src="https://resource.ewe.rs//media/ewe-go-cart/login/login-traka.png" alt="Ewe Go Cart" className={`${styles['full-width-image-bottom']}`} />

            </div>
        </>
    )
}

export default EweGoCartLogin