import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        // This is important to prevent memory leaks
        // Fixed Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. at ScrollToTop
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <button onClick={scrollToTop} className={`back-to-top ${showButton ? 'active' : ''}`}>
            <i className="fas fa-chevron-up"></i>
        </button>
    );
};

export default ScrollToTop;