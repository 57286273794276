import React, { useState, useEffect } from 'react'
// import Form from 'react-bootstrap/Form';
// import { toast } from 'react-toastify';
// import MessageInfoBox from '../components/other/MessageInfoBox';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from "../../css/EweGoCartLanding.module.css";
import { Link } from 'react-router-dom';


const EweGoCartLanding = () => {

    const userInfo = useSelector((state) => state.userInfo);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        // dispatch(getData())
    }, [])
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (

        <>
            {isMobile ? (

                // Mobilna verzija
                <div className={styles['mobile-container']}>
                    <div className={`${styles['first-container-fluid-mobile']}`}>

                        <img
                            className={`${styles['ewe-go-cart-img-top']}`}
                            src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                            alt="top-banner"
                        />
                        <div className={`${styles['first-holder-mobile']}`}>
                            <img
                                className={`${styles['ewe-go-cart-img-mobile']}`}
                                src="https://resource.ewe.rs/media/ewe-go-cart/landing/EMBRACE_THE_RACE_I_LOGO.png"
                                alt="logo"
                            />
                            <img
                                src="https://resource.ewe.rs/media/ewe-go-cart/login/login-naslov.png"
                                alt="Ewe Go Cart"
                                className={`${styles['login-title-image-mobile']}`}
                            />
                            <h2 className={`text-center ${styles['trazimo-sampiona-mobile']}`}>TRAŽIMO ŠAMPIONA</h2>
                            <h4 className={`text-center ${styles['pasus1-mobile']}`}>Svi znamo da šampion dobija mnogo više od titule. Ove jeseni EWE donosi šansu
                                da neko od vas postane šampion EWE GO-KART događaja
                                uz brojne nagrade i iznenađenja.
                            </h4 >
                            <img
                                src="https://resource.ewe.rs/media/ewe-go-cart/landing/EMBRACE_THE_RACE.png"
                                alt="Ewe Go Cart"
                                className={`text-center ${styles['embrace-title-image-mobile']}`}
                            />
                            <p className={`${styles['pasus1-mobile']}`}> jer takmičenje za titulu počinje i pre trke!</p>
                            <img
                                src="https://resource.ewe.rs/media/ewe-go-cart/landing/PEHAR.png"
                                alt="Ewe Go Cart"
                                className={`${styles['pehar-image-mobile']}`} />
                        </div>
                    </div >
                    <div className={`${styles['brown-container-fluid-mobile']}`}>
                        <h2 className={` ${styles['trazimo-sampiona-mobile']}`}>EWE GO-KART</h2>
                        <p>Ovo je sezonski događaj u kojem svako od naših partnera može postati šampion i osvajač vrednih nagrada.</p>
                        <p>Kupovinom posebno označenih artikala u periodu od 5. septembra do 15. oktobra 2024. takmičari sakupljaju bodove, a 35 najbolje rangiranih takmičara postaju učesnici trke.</p>
                        <p>Trka će biti održana 22. oktobra 2024. u Autokomerc Karting Centru, gde će se 35
                            takmičara boriti za titulu šampiona i vredne nagrade.</p>
                    </div>
                    <div className={`${styles['start-container-fluid-mobile']}`}>
                        <div className={`${styles['transparent-holder']}`}>
                            <p>
                                <span className={`${styles['red-text-mobile']}`}>ADD-TO-CART:</span>
                                <span className={`${styles['black-text-mobile']}`}>KVALIFIKACIJE</span>
                            </p>
                            <div className={`${styles['paragraph-container']}`}>
                                <p>Mi vam dajemo šansu da učestvujete u EWE GO-KART trci, ali samo od vas zavisi da li ćete biti jedan od 35 takmičara na karting stazi.</p>
                                <p>Kvalifikacije zvanično počinju <span className={`${styles['red-inline']}`}> 5. septembra 2024 </span>, od kada možete na EWE B2B portalu kupovati posebno označene artikle i time sakupljati bodove. Oznaka na svakom artiklu sadrži broj bodova koji dobijate kupovinom označenog artikla.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['text-button-holder-mobile']}`}>

                        <div className={`${styles['paragraph-container-mobile']}`}>
                            <p>Kvalifikacije traju do <span className={`${styles['red-inline']}`}>15. oktobra 2024 </span>, kada će
                                35 najbolje rangiranih -  takmičara sa najvećim
                                brojem bodova - dobiti pozivnicu za učešće u trci.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span>	Za učešće u EWE GO-KART takmičenju potrebno
                                je da se prijavite, unoseći ime, prezime i alijas koji sami odaberete.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span>	U svakom trenutku dok traju kvalifikacije svim učesnicima
                                će biti dostupna aktuelna rang lista, tj. spisak najbolje
                                rangiranih takmičara.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span> Tema za izbor alijasa  je Formula 1 i vaš alijas može biti ime i
                                prezime vozača Formule 1,  naziv konstruktora, staze,
                                proizvođača guma ili drugi pojam koji je u vezi sa Formulom 1.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span>	Tokom trajanja kvalifikacija, svi prijavljeni takmičari će imati
                                dostupan pregled trenutnog broja sopstvenih bodova.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span>	Za sakupljanje bodova se računa promet na označene
                                artikle napravljen od 5. septembra, bez obzira na to da li
                                ste se za takmičenje prijavili 5. septembra ili kasnije. Prijava je
                                moguća najkasnije do 30. septembra.</p>
                        </div>
                        <div className={`row`}>
                            <div className='col-sm-6 text-center'>
                                <Link to="/ewe-go-cart-login">
                                    <button className={`${styles['login-page-button-mobile']}`}>PRIJAVA</button>
                                </Link>
                            </div>
                            <div className='col-sm-6 text-center'>
                                <Link to="/ewe-go-cart-ranking">
                                    <button className={`${styles['ranking-page-button-mobile']}`}>RANG LISTA</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['go-kart-container-fluid-mobile']}`}>
                        <div className={`${styles['transparent-holder25']}`}>
                            <h1>
                                <span className={`${styles['red-text-mobile']}`}>GO-KART:</span>
                                <span className={`${styles['black-text-mobile']}`}>PUN GAS</span>
                            </h1>
                            <div className={`${styles['paragraph-container-mobile']}`}>
                                <p>EWE GO-KART takmičenje biće krunisano trkom na
                                    karting stazi u Autokomerc Karting Centru, u utorak,
                                    22. oktobra 2024. Najbolje rangirani takmičari, sa najvećim
                                    brojem bodova na dan završetka kvalifikacija <span className={`${styles['red-inline']}`}>15. oktobra 2024. </span>
                                    biće pozvani da se takmiče za titulu EWE GO-KART šampiona
                                    kroz takmičarski program.</p>
                                <p>Za tri najbrža takmičara rezervisano je mesto na pobedničkom
                                    postolju i neizostavno proglašenje pobednika uz šampanjac.</p>
                                <p><span className={`${styles['red-inline']}`}>•</span> Pozivnica za takmičare važi za 2 osobe.</p>
                                <p><span className={`${styles['red-inline']}`}>•</span> Samo jedna osoba je takmičar (vozač kartinga).</p>
                                <p><span className={`${styles['red-inline']}`}>•</span> Za sve takmičare obezbeđena je neophodna zaštitna oprema.</p>
                                <p><span className={`${styles['red-inline']}`}>•</span> Svi prisutni na EWE GO-KART događaju imaju obezbeđenu
                                    hranu i piće na dan trke.</p>
                                <p><span className={`${styles['red-inline']}`}>•</span> Na dan trke sve takmičare očekuje veliko iznenađenje!</p>

                            </div>
                        </div>
                    </div>
                    <div className={`${styles['brown-container-fluid-mobile']}`}>
                        <h2 className={` ${styles['trazimo-sampiona-mobile']}`}>KARTODROM</h2>
                        <p>Na kartodromu Autokomerc Karting Centra zagarantovana je adrenalinska
                            avantura za sve takmičare.</p>
                        <p>Na kartodromu Autokomerc Karting Centra zagarantovana je adrenalinska
                            avantura za sve takmičare.</p>
                        <p>Uz obezbeđenu zaštitnu opremu i poštovanje pravila staze, pred vama je
                            dinamična vožnja i zabava za pamćenje!</p>
                    </div>
                    <div className={`${styles['second-text-button-holder-mobile']}`}>
                        <div className={`${styles['heading-holder-mobile']}`}>
                            <p>
                                <span className={`${styles['red-text-mobile']}`}>EYES ON THE PRIZE:</span>
                            </p>
                            <p>
                                <span className={`${styles['black-text-mobile']}`}>ŠTA POSLE TRKE</span>
                            </p>
                        </div>
                        {/* <p>EWE GO-KART šampion može biti samo jedan, ali na programu je celodnevna
                            zabava za sve prisutne.
                        </p>
                        <p>Prijavite se i počnite sa sakupljanjem bodova što pre.</p > */}
                        <div className={`${styles['paragraph-container-mobile']}`}>
                            <p>EWE GO-KART šampion može biti samo jedan, ali na programu je celodnevna
                                zabava za sve prisutne.
                            </p>
                            <p><span className={`${styles['red-inline']}`}>•</span> Pobednički pehari za osvojeno prvo, drugo i treće mesto.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span> Vredne nagrade za deset najbržih takmičara finalne trke.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span> Pokloni za sve takmičare.</p>
                            <p><span className={`${styles['red-inline']}`}>•</span> Posebno iznenađenje za sve učesnike trke.</p>
                            <p>Prijavite se i počnite sa sakupljanjem bodova što pre.</p >
                        </div>
                        <div className={`text-center ${styles['paragraph-container-button-mobile']}`}>
                            <Link to="/ewe-go-cart-login">
                                <button className={`${styles['login-page-button-mobile']}`}>
                                    <span>PRIJAVA</span><span>Prijavite se za EWE GO-KART</span>
                                </button>
                            </Link>
                        </div>

                        <div className={`text-center ${styles['paragraph-container-button-mobile']}`}>
                            <Link to="/ewe-go-cart-ranking">
                                <button className={`${styles['ranking-page-button-mobile']}`}>
                                    <span>RANG LISTA</span><span>Pogledajte trenutna rangiranja</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={`${styles['third-text-button-holder-mobile']}`}>
                        <div className={`${styles['final-heading-container-mobile']}`}>
                            <h1>
                                <span className={`${styles['black-text-mobile']}`}>Vidimo se u</span>
                                <span className={`${styles['red-text-mobile']}`}>  pobedničkom krugu!</span>
                            </h1>
                        </div>
                        <div className={` ${styles['paragraph-container40-mobile']}`}>
                            <img
                                className={`${styles['zastavica-mobile']}`}
                                src="https://resource.ewe.rs/media/ewe-go-cart/landing/ZASTAVICE.png"
                                alt="zastavica"
                            />
                        </div>
                    </div>
                    <img
                        className={`${styles['ewe-go-cart-img-bottom-mobile']}`}
                        src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                        alt="bottom-banner"
                    />
                </div >

            ) : (
                // Desktop verzija
                <div className={`container-fluid ${styles['main-container']}`}>
                    <>
                        <div className="row">
                            <div className="col-12 mx-auto text-center">
                                <img
                                    className={`${styles['ewe-go-cart-img-top']}`}
                                    src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                                    alt="top-banner"
                                />
                            </div>
                        </div>
                        <div className={` ${styles['second-button-holder1']}`}></div>
                        <div className={styles['wrapper-ewe-go-cart']}>

                            <div className={`row ${styles['ewe-go-cart']}`}>
                                <div className="col-12 mx-auto text-center">
                                    <img
                                        className={`${styles['ewe-go-cart-img']}`}
                                        src="https://resource.ewe.rs/media/ewe-go-cart/landing/EMBRACE_THE_RACE_I_LOGO.png"
                                        alt="logo"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mx-auto text-center">
                                    <img
                                        src="https://resource.ewe.rs/media/ewe-go-cart/login/login-naslov.png"
                                        alt="Ewe Go Cart"
                                        className={`${styles['login-title-image']}`}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className={`col-12 mx-auto text-center ${styles['trazimo-sampiona-holder']}`}>
                                    <div className="col-12">
                                        <h2 className={`text-center ${styles['trazimo-sampiona']}`}>TRAŽIMO ŠAMPIONA</h2>
                                    </div>
                                    <div className="col-12 col-lg-8 mx-auto">
                                        <h4 className={`text-center ${styles['pasus1']}`}>
                                            Svi znamo da šampion dobija mnogo više od titule. Ove jeseni EWE donosi šansu
                                            da neko od vas postane šampion EWE GO-KART događaja
                                            uz brojne nagrade i iznenađenja.
                                        </h4>
                                    </div>
                                    <div className="col-12">
                                        <div className={`text-center ${styles['flex-embrace-text']}`}>
                                            <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/EMBRACE_THE_RACE.png" alt="Ewe Go Cart" className={`${styles['embrace-title-image']}`} />
                                            <p className={`${styles['pasus2']}`}>, jer takmičenje za titulu počinje i pre trke!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mx-auto text-center">
                                    <div className={`${styles['pehar-container']}`}>
                                        <div className={`${styles['pehar']}`}>
                                            <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/PEHAR.png" alt="Ewe Go Cart" className={`${styles['pehar-image']}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className={`text-center ${styles['braon-prozor-holder']}`}>
                                        <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/GORNJI_BRAON_PROZOR_(SA%20TEKSTOM).png" alt="Ewe Go Cart" className={`${styles['braonProzor-image']}`} />
                                    </div>
                                </div>
                            </div>
                            <div className={`text-center ${styles['race-content-holder']}`}>
                                <div className="row">
                                    <div className="col-8 col-lg-8 col-xl-6 col-xxl-6">
                                        <div className={`${styles['race-content-holder-text']}`}>
                                            <h1>
                                                <span className={`${styles['red-text']}`}>ADD-TO-CART:</span>
                                                <span className={`${styles['black-text']}`}> KVALIFIKACIJE</span>
                                            </h1>
                                            <div className={`${styles['paragraph-container']}`}>
                                                <p>Mi vam dajemo šansu da učestvujete u EWE GO-KART trci, ali samo od vas zavisi da li ćete biti jedan od 35 takmičara na karting stazi.</p>
                                                <p>Kvalifikacije zvanično počinju <span className={`${styles['red-inline']}`}> 5. septembra 2024 </span>, od kada možete na EWE B2B portalu kupovati posebno označene artikle i time sakupljati bodove. Oznaka na svakom artiklu sadrži broj bodova koji dobijate kupovinom označenog artikla.</p>
                                            </div>
                                            <div className={`${styles['image-container-stoperica']}`}>
                                                <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/STOPERICA%20I%20BRZINOMER.png" alt="Ewe Go Cart" className={`${styles['stopwatch-image']}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row ${styles['content-right']}`}>
                                    <div className="col-6 col-lg-8">
                                        <div className={`${styles['race-content-holder-text1']}`}>
                                            <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/SIVE_GUME.png" alt="Ewe Go Cart" className={`${styles['fuel-image']}`} />
                                            <div className={`${styles['paragraph-container1']}`}>
                                                <p>Kvalifikacije traju do <span className={`${styles['red-inline']}`}>15. oktobra 2024 </span>, kada će 35 najbolje rangiranih - takmičara sa najvećim brojem bodova - dobiti pozivnicu za učešće u trci.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span>Za učešće u EWE GO-KART takmičenju potrebno je da se prijavite, unoseći ime, prezime i alijas koji sami odaberete.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> U svakom trenutku dok traju kvalifikacije svim učesnicima će biti dostupna aktuelna rang lista, tj. spisak najbolje rangiranih takmičara.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Tema za izbor alijasa je Formula 1 i vaš alijas može biti ime i prezime vozača Formule 1, naziv konstruktora, staze, proizvođača guma ili drugi pojam koji je u vezi sa Formulom 1.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Tokom trajanja kvalifikacija, svi prijavljeni takmičari će imati dostupan pregled trenutnog broja sopstvenih bodova.</p>
                                                <p className={`${styles['last-red-inline']}`}><span className={`${styles['red-inline']}`}>•</span> Za sakupljanje bodova se računa promet na označene artikle napravljen od 5. septembra, bez obzira na to da li ste se za takmičenje prijavili 5. septembra ili kasnije. Prijava je moguća najkasnije do 30. septembra.</p>
                                            </div>
                                            <div className={`row ${styles['content-center']}`}>
                                                <div className='text-center'>
                                                    <Link to="/ewe-go-cart-login">
                                                        <button className={`${styles['login-page-button']}`}>PRIJAVA</button>
                                                    </Link>
                                                </div>
                                                <div className='text-center'>
                                                    <Link to="/ewe-go-cart-ranking">
                                                        <button className={`${styles['ranking-page-button']}`}>RANG LISTA</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row"> */}
                                <div className={`row ${styles['content-left']}`}>
                                    <div className="col-6 col-lg-8">
                                        <div className={`${styles['race-content-holder-text2']}`}>
                                            {/* <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/crvene_i_sive_gume.png" alt="Ewe Go Cart" className={`${styles['fuel-image1']}`} /> */}
                                            <img src="https://resource.ewe.rs/media/ewe-go-cart/landing/SIVE_GUME.png" alt="Ewe Go Cart" className={`${styles['fuel-image1']}`} />
                                            <h1>
                                                <span className={`${styles['red-text']}`}>GO-KART:</span>
                                                <span className={`${styles['black-text']}`}> PUN GAS</span>
                                            </h1>
                                            <div className={`${styles['paragraph-container']}`}>
                                                <p>EWE GO-KART takmičenje biće krunisano trkom na karting stazi u Autokomerc Karting Centru, u utorak, 22. oktobra 2024. Najbolje rangirani takmičari, sa najvećim brojem bodova na dan završetka kvalifikacija <span className={`${styles['red-inline']}`}>15. oktobra 2024. </span> biće pozvani da se takmiče za titulu EWE GO-KART šampiona kroz takmičarski program.</p>
                                                <p>Za tri najbrža takmičara rezervisano je mesto na pobedničkom postolju i neizostavno proglašenje pobednika uz šampanjac.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Pozivnica za takmičare važi za 2 osobe.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Samo jedna osoba je takmičar (vozač kartinga).</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Za sve takmičare obezbeđena je neophodna zaštitna oprema.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Svi prisutni na EWE GO-KART događaju imaju obezbeđenu hranu i piće na dan trke.</p>
                                                <p><span className={`${styles['red-inline']}`}>•</span> Na dan trke sve takmičare očekuje veliko iznenađenje!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles['second-part']}`}>
                                <img
                                    className={` ${styles['kartodrom']}`}
                                    src="https://resource.ewe.rs/media/ewe-go-cart/landing/kartodrom.png"
                                    alt="logo"
                                />
                                <div className={` ${styles['paragraph-container2']}`}>
                                    <h1>
                                        <span className={`${styles['black-text']}`}>EYES ON THE PRIZE:</span>
                                        <span className={`${styles['red-text']}`}>  ŠTA POSLE TRKE</span>
                                    </h1>
                                    <p>EWE GO-KART šampion može biti samo jedan, ali na programu je celodnevna
                                        zabava za sve prisutne.
                                    </p>
                                    <p><span className={`${styles['red-inline']}`}>•</span> Pobednički pehari za osvojeno prvo, drugo i treće mesto.</p>
                                    <p><span className={`${styles['red-inline']}`}>•</span> Vredne nagrade za deset najbržih takmičara finalne trke.</p>
                                    <p><span className={`${styles['red-inline']}`}>•</span> Pokloni za sve takmičare.</p>
                                    <p><span className={`${styles['red-inline']}`}>•</span> Posebno iznenađenje za sve učesnike trke.</p>

                                    <p>Prijavite se i počnite sa sakupljanjem bodova što pre.</p >
                                    <div className={` ${styles['second-button-holder']}`}>
                                        <div className={` ${styles['links-container']}`}>
                                            <div className={` ${styles['paragraph-container30']}`}>
                                                <Link to="/ewe-go-cart-login">
                                                    <button className={`${styles['login-page-button']}`}>
                                                        <span>PRIJAVA</span><span>Prijavite se za EWE GO-KART</span>
                                                    </button>
                                                </Link>
                                            </div>

                                            <div className={` ${styles['paragraph-container30']}`}>
                                                <Link to="/ewe-go-cart-ranking">
                                                    <button className={`${styles['ranking-page-button']}`}>
                                                        <span>RANG LISTA</span><span>Pogledajte trenutna rangiranja</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className={` ${styles['paragraph-container40']}`}>
                                            <img
                                                className={`${styles['zastavica']}`}
                                                src="https://resource.ewe.rs/media/ewe-go-cart/landing/ZASTAVICE.png"
                                                alt="zastavica"
                                            />
                                        </div>
                                    </div>
                                    <div className={`${styles['final-heading-container']}`}>
                                        <h1>
                                            <span className={`${styles['black-text']}`}>Vidimo se u</span>
                                            <span className={`${styles['red-text']}`}>  pobedničkom krugu!</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            className={`${styles['ewe-go-cart-img-bottom']}`}
                            src="https://resource.ewe.rs/media/ewe-go-cart/login/login-traka.png"
                            alt="bottom-banner"
                        />
                    </>
                </div >
            )}


        </>

    )
}

export default EweGoCartLanding