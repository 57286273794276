import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import PageBanner from '../components/banner/PageBanner';
import OutletCategoryItem from '../components/other/OutletCategoryItem';
import PageHelmet from '../components/helmet/PageHelmet';

import { removeSpecialChar } from '../helperFunctions'
import { useTranslation } from 'react-i18next';

const OutletPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let pathname = history.location.pathname;
    const getCatalogMenuList = useSelector((state) => state.getCatalogMenuList);
    const [pageSeoData, setPageSeoData] = useState({});
    // console.log(getCatalogMenuList, 'getCatalogMenuList') 
    const getCatalogMenuListItems = getCatalogMenuList.filter((x) => x._id === 13);
    // console.log(getCatalogMenuListItems, 'getCatalogMenuListItems')
    const items = getCatalogMenuListItems.length > 0 ? getCatalogMenuListItems[0].subCategories.length > 0 && getCatalogMenuListItems[0].subCategories[0].subCategories : [];
    // console.log(items, 'items')
    const queries = useMediaQuery('(min-width: 1500px)')
    const queries1 = useMediaQuery('(max-width: 1730px)')
    const { t } = useTranslation(['outlet', 'routes']);

    useEffect(() => {
        dispatch(getPageSeo(355)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(355, 'PAGE'));
    }, []);
    // console.log(items, 'items');

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content outlet-page">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-center banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="355" />
                            </div>
                        </div>
                        <div className="col-sm-12 outlet-links-wrapper">
                            <div className="row justify-content-center">
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#F0C900" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=nekorisceno_44852`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_nekorisceno.png'} alt="ewe" />
                                            <p className="outlet-desc">{t('outlet:nekoriscena')}<br />{t('outlet:roba')}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#71CE01" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=neadekvatna-ambalaza_44854`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_ambalaza.png'} alt="ewe" />
                                            <p className="outlet-desc">{t('outlet:neadekvatna')}<br />{t('outlet:ambalaza')}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#888687" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=korisceno_57505`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_korisceno.png'} alt="ewe" />
                                            <p className="outlet-desc">{t('outlet:tragovi')}<br />{t('outlet:koriscenja')}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#6BBFD7" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=nepotpuno-pakovanje_44873`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_pakovanje.png'} alt="ewe" />
                                            <p className="outlet-desc">{t('outlet:nepotpuno')}<br />{t('outlet:pakovanje')}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#EA7639" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=osteceno-izgrebano_44855`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_osteceno.png'} alt="ewe" />
                                            <p className="outlet-desc">{t('outlet:osteceno')}<br />{t('outlet:izgrebano')}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} col-6 outlet-links`} style={{ borderColor: "#E84644" }}>
                                    <Link to={`/${t('routes:katalog')}/podkategorija/14?stanje_2213=izlozeni-model_61458`}>
                                        <div className="outlet-img-wrapper">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/ikonica_nedostatak.png'} alt="ewe" />
                                            <p className="outlet-desc" style={{ marginTop: "8px" }}>{t('outlet:nedostatak')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            {/* <div className="col-sm-12 logout-page-products"> */}
                            <div className="row">
                                {items.length > 0 ? (
                                    items.map((item, index) => {
                                        return (
                                            <div key={index} className={`${queries ? "col-md-2" : "col-md-4"} col-sm-6`}>
                                                <Link to={`/${t('routes:katalog')}/podpodkategorija/${item._id}/${removeSpecialChar(item.acName)}`} className="outlet-page-item text-center">
                                                    <OutletCategoryItem item={item} />
                                                </Link>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="alert alert-danger w-100">{t('outlet:nemaKategorija')}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OutletPage;