import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";
import { getItem } from '../../indexedDB';
import CryptoJS from 'crypto-js';

import CustomCatalogPdfTemplate from "./templates/CustomCatalogPdfTemplate";
// import SecureLS from 'secure-ls';

const CustomExportCatalogPDF = () => {
    const [productsList, setProductsList] = useState(null);
    const [loading, setLoading] = useState(true);
    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const activeVat = useSelector((state) => state.activeVat.item);
    
    useEffect(() => {
        const fetchCatalogItems = async () => {
            const encryptedData = await getItem('catalogItems');
            if (encryptedData) {
                const bytes = CryptoJS.AES.decrypt(encryptedData, 'Č1k1n@T@jn@');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setProductsList(decryptedData);
            }
            setLoading(false);
        };

        fetchCatalogItems();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {productsList.length > 0 && <CustomCatalogPdfTemplate list={productsList} currency={currency} activeVat={activeVat} />}
        </PDFViewer>
    )
}

export default CustomExportCatalogPDF;
