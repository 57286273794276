import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomExportCatalogItem from '../components/other/CustomExportCatalogItem';
import PageBanner from '../components/banner/PageBanner';
import { roundNumberCeil, removeSpecialChar } from "../helperFunctions";
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';
import { setItem, getItem } from '../indexedDB';
import CryptoJS from 'crypto-js';

const CustomExportCatalog = () => {

    const params = useParams();
    const userInfo = useSelector((state) => state.userInfo);
    const catalogProducts = useSelector((state) => state.catalogProducts.items);
    const getActionProducts = useSelector((state) => state.getActionProducts.items);
    const activeVat = useSelector((state) => state.activeVat.item);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [isVat, setIsVat] = useState(activeVat);
    const { t } = useTranslation(['customExportCatalog', 'routes']);
    let ls = new SecureLS({ encodingType: 'aes' });

    const [catalogItems, setCatalogItems] = useState([]);
    const [inputValuePercentage, setInputValuePercentage] = useState(userInfo.userInfo.company.department[0].user[0].userSettings[0].anMargin ? userInfo.userInfo.company.department[0].user[0].userSettings[0].anMargin : 0);

    // Učitavanje podataka iz IndexedDB
    useEffect(() => {
        const fetchCatalogItems = async () => {
            const encryptedData = await getItem('catalogItems');
            if (encryptedData) {
                const bytes = CryptoJS.AES.decrypt(encryptedData, 'Č1k1n@T@jn@');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setCatalogItems(decryptedData);
            }
        };

        fetchCatalogItems();
    }, [catalogProducts, getActionProducts]);

    //brisanje proizvoda iz liste
    const removeItemFromCatalogFunction = async (item) => {
        const encryptedData = await getItem('catalogItems');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, 'Č1k1n@T@jn@');
            const tmpCatalog = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const index = tmpCatalog.findIndex((o) => o._id === item._id);
            if (index !== -1) tmpCatalog.splice(index, 1);
            const newEncryptedData = CryptoJS.AES.encrypt(JSON.stringify(tmpCatalog), 'Č1k1n@T@jn@').toString();
            await setItem('catalogItems', newEncryptedData);
            setCatalogItems(tmpCatalog);
        }
    }

    //promena vrednosti margine globalno
    const setInputValuePercentageFunction = async (val) => {
        setInputValuePercentage(val);
        const encryptedData = await getItem('catalogItems');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, 'Č1k1n@T@jn@');
            const tmpCatalogList = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            tmpCatalogList.forEach(element => {
                element.anPricePdf = roundNumberCeil(element.anPrice + (element.anPrice * val / 100));
            });
            const newEncryptedData = CryptoJS.AES.encrypt(JSON.stringify(tmpCatalogList), 'Č1k1n@T@jn@').toString();
            await setItem('catalogItems', newEncryptedData);
            setCatalogItems(tmpCatalogList);
        }
        // ls.set('catalogItems', tmpCatalogList);
    }


    useEffect(() => {
        // setCatalogItems(ls.get('catalogItems'))
        const fetchCatalogItems = async () => {
            const encryptedData = await getItem('catalogItems');
            if (encryptedData) {
                const bytes = CryptoJS.AES.decrypt(encryptedData, 'Č1k1n@T@jn@');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setCatalogItems(decryptedData);
            }
        };

        fetchCatalogItems();
    }, [catalogProducts, getActionProducts])

    useEffect(() => {
        setIsVat(activeVat)
    }, [activeVat])

    return (
        <div className="container-content container-catalog-content contact-page-content">
            <div className="container-fluid-custom">
                <div className="row">
                    <div className="col-sm-12">
                        <PageBanner categoryType="" categoryId="" pageId="999" />
                        <div className="contact-title">
                            <h2>{t('customExportCatalog:naslov1')}</h2>
                        </div>
                        <blockquote className="description-page-detail">{t('customExportCatalog:podnaslov1')}</blockquote>
                        <div className="container-custom-export-catalog">
                            <div className="row">
                                <div className="col-sm-2">
                                    <div>{t('customExportCatalog:marza')}</div>
                                    <div className="container-custom-export-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={inputValuePercentage}
                                            onChange={(e) => setInputValuePercentageFunction(e.target.value)}
                                        /> &nbsp;%
                                    </div>
                                </div>
                            </div>
                            <div className="custom-offer-table">
                                <table className="table table-catalog-list">
                                    <thead>
                                        <tr>
                                            <th>{t('customExportCatalog:kolona1')}</th>
                                            <th>{t('customExportCatalog:kolona2')}</th>
                                            <th>{t('customExportCatalog:kolona3')}</th>
                                            <th className="column-custom-export-width">{t('customExportCatalog:kolona4')}</th>
                                            <th className="column-custom-export-width">{t('customExportCatalog:kolona5')}</th>
                                            <th className="column-custom-export-width">{t('customExportCatalog:kolona6')} {activeCurrency.acShortName} {isVat == 1 ? t('customExportCatalog:saPdv') : t('customExportCatalog:bezPdv')}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {catalogItems && catalogItems.map((item, key) => {
                                            return (
                                                <CustomExportCatalogItem inputValuePercentage={inputValuePercentage} removeItemFromCatalog={removeItemFromCatalogFunction} item={item} key={key} />
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-4 custom-offer-create">
                                    <a href={`/${t('routes:pdfPonudaKatalog')}/${removeSpecialChar(params.categoryName)}`} target="_blank" rel='noreferrer'>
                                        <button className="btn btn-info">{t('customExportCatalog:dugme')}</button>
                                    </a>
                                </div>
                                <div className="col-sm-12">
                                    <div className="alert alert-danger"><i className="fas fa-info-circle"></i> {t('customExportCatalog:disclaimer')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomExportCatalog;